import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import video from "../../Containers/NewLandingPage/Video";
import { play } from "../../Containers/NewLandingPage/imports";

const About = () => {
  const { t } = useTranslation();
  const [playing, setPlaying] = useState(false);
  const { data } = video();
  const iframe = useRef(null);

  const generateIframe = () => {
    setPlaying(!playing);
    iframe.current.append();
  };

  const thumbnailStyle = {
    display: playing ? "none" : "block",
  };

  const iframeStlye = {
    display: !playing ? "none" : "block",
  };

  return (
    <div className="about">
      <div className="container">
        <div className="about__inner">
          <div className="about__video" ref={iframe}>
            <img
              className="about__video-thumbnail"
              src={data.thumbnail}
              alt=""
              style={thumbnailStyle}
            />
            <iframe
              style={iframeStlye}
              className="about__video-iframe"
              src={data.src}
              title="YouTube video player"
              frameBorder="0"
              width={"100%"}
              height={"100%"}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <img
              className="about__video-play"
              src={play}
              alt=""
              onClick={generateIframe}
              style={thumbnailStyle}
            />
          </div>
          <div className="about__content">
            <h3 className="about__title">{t("AboutTitle_i18n")}</h3>
            <p className="about__text">{t("AboutText_i18n")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

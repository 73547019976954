import React, { useEffect } from "react";
import Hero from "./Hero.jsx";
import About from "./About.jsx";
import HowItWorks from "./HowItWorks.jsx";
import Features from "./Features.jsx";
import Cooperation from "./Cooperation.jsx";
import Support from "./Support.jsx";
import Apps from "./Apps.jsx";
import DecelerationData from "./DecelerationData.jsx";
import { useTranslation } from "react-i18next";

import howItWorks from "../../Containers/NewLandingPage/HowItWorksMain";
import featuresMainPage from "../../Containers/NewLandingPage/FeaturesMain";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";

const Main = () => {
  // console.log("called");
  const { texts } = howItWorks();
  const { features } = featuresMainPage();
  const { t } = useTranslation();

  return (
    <main className="main">
      <Hero />
      <About />
      <HowItWorks title={t("HowItWorksTitle_i18n")} list={texts} />
      <Features list={features} />
      <Cooperation />
      <Support />
      <Apps />
      <DecelerationData />
    </main>
  );
};

export default Main;

//In this file constants variables are defined, which is used in different file.
import React from "react";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import AcUnitOutlinedIcon from "@mui/icons-material/AcUnitOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import SentimentVeryDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentVeryDissatisfiedOutlined";
import { ReactComponent as ParentChild } from "../assets/images/ParentChild.svg";
import { ReactComponent as WaterDrop } from "../assets/images/waterDrop.svg";
import { ReactComponent as Location } from "../assets/images/location.svg";
import { ReactComponent as Triangle } from "../assets/images/triangle.svg";
import { ReactComponent as ApprovedList } from "../assets/images/approved-list.svg";
import { ReactComponent as Settings } from "../assets/images/settings.svg";
import { ReactComponent as NightMode } from "../assets/images/night-mode.svg";
import { ReactComponent as WhoAmI } from "../assets/images/who-am-i.svg";
import { ReactComponent as SelfLock } from "../assets/images/self-lock.svg";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import FaceOutlinedIcon from "@mui/icons-material/FaceOutlined";
import ThumbDownAltOutlinedIcon from "@mui/icons-material/ThumbDownAltOutlined";
import AppBlockingOutlinedIcon from "@mui/icons-material/AppBlockingOutlined";
import SendToMobileOutlinedIcon from "@mui/icons-material/SendToMobileOutlined";

export const componentList = {
  parent: ["approval-lists", "setting", "night-mode", "who-am-i", "self-lock"],
  child: ["approval-lists", "setting", "night-mode", "who-am-i"],
  parentInChild: ["approval-lists", "setting", "night-mode", "who-am-i"],
  childInParent: ["approval-lists", "setting", "night-mode", "who-am-i"],
  packageUser: [
    "approval-lists",
    "global-approval-lists",
    "setting",
    "who-am-i",
  ],
  packageInModerator: ["approval-lists", "setting", "who-am-i"],
};

export const classNames = {
  "approval-lists": "bi bi-list-ul",
  "global-approval-lists": "bi bi-list-ul",
  setting: "bi bi-gear-fill",
  "night-mode": "bi bi-moon-fill",
  "who-am-i": "bi bi-person-fill",
  "add-kid": "bi bi-person-plus-fill",
  "self-lock": "bi bi-lock-fill",
};
export const sidebarIcon = {
  "approval-lists": <ApprovedList />,
  "global-approval-lists": <ApprovedList />,
  setting: <Settings />,
  "night-mode": <NightMode />,
  "who-am-i": <WhoAmI />,
  "add-kid": "bi bi-person-plus-fill",
  "self-lock": <SelfLock />,
};

export const showPageTitle = {
  "/approval-lists": "Approval Lists",
  "/global-approval-lists": "Global Approval Lists",
  "/setting": "Settings",
  "/night-mode": "Night Mode",
  "/who-am-i": "Who am I",
  "/add-kid": "bi bi-person-plus-fill",
  "/self-lock": "Self Lock",
};

export const icons = {
  child: {
    title: "kid_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <GroupsOutlinedIcon />,
        toolTip: "parent",
      },
      {
        icon: <ParentChild />,
        toolTip: "child",
      },
      {
        icon: <FaceOutlinedIcon />,
        toolTip: "face",
      },
      {
        icon: <AccessTimeOutlinedIcon />,
        toolTip: "clock",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <PersonAddOutlinedIcon />,
        toolTip: "plusPerson",
      },
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUp",
      },
      {
        icon: <ParentChild />,
        className: "not-allow",
        toolTip: "childKidNotAllow",
      },
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        className: "not-allow",
        toolTip: "dissatisfiedNotAllow",
      },
      {
        icon: <ThumbDownAltOutlinedIcon />,
        toolTip: "thumbsDown",
      },
    ],
    stop: [
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        toolTip: "dissatisfiedKid",
      },
      {
        icon: <ParentChild />,
        toolTip: "childKid",
      },
    ],
  },
  melt: {
    title: "frozenLockUser_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <GroupsOutlinedIcon />,
        toolTip: "parent",
      },
      {
        icon: <ParentChild />,
        toolTip: "child",
      },
      {
        icon: <AcUnitOutlinedIcon />,
        toolTip: "acUnit",
      },
      {
        icon: <AccessTimeOutlinedIcon />,
        toolTip: "clock",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <PersonAddOutlinedIcon />,
        toolTip: "plusPerson",
      },
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUp",
      },
      {
        icon: <ParentChild />,
        className: "not-allow",
        toolTip: "childNotAllow",
      },
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        className: "not-allow",
        toolTip: "dissatisfiedNotAllow",
      },
    ],
    stop: [
      {
        icon: <WaterDrop />,
        toolTip: "waterDrop",
      },
    ],
  },
  simple: {
    title: "simpleLockUser_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <GroupsOutlinedIcon />,
        toolTip: "parent",
      },
      {
        icon: <ParentChild />,
        toolTip: "child",
      },
      {
        icon: <LockOpenIcon />,
        toolTip: "unlock",
      },
      {
        icon: <AccessTimeOutlinedIcon />,
        toolTip: "clock",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <PersonAddOutlinedIcon />,
        toolTip: "plusPerson",
      },
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUp",
      },
      {
        icon: <ParentChild />,
        className: "not-allow",
        toolTip: "childNotAllow",
      },
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        className: "not-allow",
        toolTip: "dissatisfiedNotAllow",
      },
    ],
    stop: [
      {
        icon: <LockOutlinedIcon />,
        toolTip: "lock",
      },
    ],
  },
  gps: {
    title: "gpsLockUser_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <GroupsOutlinedIcon />,
        toolTip: "parent",
      },
      {
        icon: <ParentChild />,
        toolTip: "child",
      },
      {
        icon: <Location />,
        toolTip: "location",
      },
      {
        icon: <AccessTimeOutlinedIcon />,
        toolTip: "clock",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <PersonAddOutlinedIcon />,
        toolTip: "plusPerson",
      },
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUp",
      },
      {
        icon: <ParentChild />,
        className: "not-allow",
        toolTip: "childNotAllow",
      },
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        className: "not-allow",
        toolTip: "dissatisfiedNotAllow",
      },
    ],
    stop: [
      {
        icon: <Location />,
        toolTip: "locationUnlock",
      },
    ],
  },
  parent: {
    title: "freeUser_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <GroupsOutlinedIcon />,
        toolTip: "parent",
      },
      {
        icon: <ParentChild />,
        toolTip: "child",
      },
      {
        icon: <LightbulbOutlinedIcon />,
        toolTip: "bulb",
      },
      {
        icon: <AccessTimeOutlinedIcon />,
        toolTip: "clock",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <PersonAddOutlinedIcon />,
        toolTip: "plusPerson",
      },
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUp",
      },
      {
        icon: <ParentChild />,
        toolTip: "childRelation",
      },
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        toolTip: "dissatisfied",
      },
    ],
    stop: [
      {
        icon: <LockOutlinedIcon />,
        toolTip: "lockFree",
      },
      {
        icon: <ParentChild />,
        toolTip: "childFree",
      },
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        toolTip: "dissatisfiedFree",
      },
    ],
  },
  freeze: {
    title: "meltLockUser_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <GroupsOutlinedIcon />,
        toolTip: "parent",
      },
      {
        icon: <ParentChild />,
        toolTip: "child",
      },
      {
        icon: <WaterDrop />,
        toolTip: "waterDropMelted",
      },
      {
        icon: <AccessTimeOutlinedIcon />,
        toolTip: "clock",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <PersonAddOutlinedIcon />,
        toolTip: "plusPerson",
      },
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUp",
      },
      {
        icon: <ParentChild />,
        className: "not-allow",
        toolTip: "childNotAllow",
      },
      {
        icon: <SentimentVeryDissatisfiedOutlinedIcon />,
        className: "not-allow",
        toolTip: "dissatisfiedNotAllow",
      },
    ],
    stop: [
      {
        icon: <LightbulbOutlinedIcon />,
        toolTip: "bulbMelted",
      },
      {
        icon: <AcUnitOutlinedIcon />,
        toolTip: "acUnitMelted",
      },
    ],
  },
  packageUser: {
    title: "packageUser_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <AppBlockingOutlinedIcon />,
        toolTip: "appBlockPackageUser",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUpPackageUser",
      },
    ],
    stop: [
      {
        icon: <SendToMobileOutlinedIcon />,
        toolTip: "sendToMobile",
      },
    ],
  },
  packageModerator: {
    title: "moderatorUser_i18n",
    whoAmI: [
      {
        icon: <FingerprintIcon />,
        toolTip: "fingerprint",
      },
      {
        icon: <AppBlockingOutlinedIcon />,
        toolTip: "appBlockModerator",
      },
      {
        icon: <MonetizationOnOutlinedIcon />,
        toolTip: "dollar",
      },
    ],
    relation: [
      {
        icon: <ThumbUpAltOutlinedIcon />,
        toolTip: "thumbsUpModerator",
      },
    ],
    stop: [
      {
        icon: <Triangle />,
        toolTip: "triangle",
      },
    ],
  },
};

export const setting = {
  generalSettings: {
    value: [
      "allowAllChannels",
      "allowBots",
      "allowAllPublicGroups",
      "allowAllPrivateGroups",
      "allowAllPublicUsers",
    ],
    label: [
      "allChannel_i18n",
      "allBots_i18n",
      "allPublicGroups_i18n",
      "allHugePrivateGroups_i18n",
      "allPublicUser_i18n",
    ],
  },
  specialSettings: {
    value: ["allowAllComments", "allowDTA", "allowAllPictures"],
    label: ["allComment_i18n", "DTA_i18n", "profilePicture_i18n"],
  },
  girlSettings: {
    value: ["FREE", "TEEN", "LITTLE"],
    label: ["free_i18n", "teen_i18n", "little_i18n"],
  },
};

export const TYPE_OF_ACTION = {
  ADD_CHAT: "addChat",
  PARENT_ADD_KID: "parentAddKid",
  REMOVE_CHANNEL: "removeChannel",
  NIGHT_MODE: "nightMode",
  REMOVE_NIGHT_MODE: "removeNightMode",
  KID_REQUEST: "kidRequest",
  PARENT_APPROVED: "parentApproved",
  KID_APPROVED: "kidApproved",
  PARENT_DENIED: "parentDenied",
  KID_DENIED: "kidDenied",
  SIMPLE_LOCK: "simpleLock",
  FROZEN_ICE_LOCK: "frozenIceLock",
  START_FROZEN_ICE_LOCK: "startFrozenIceLock",
  STOP_FROZEN_ICE_LOCK: "stopFrozenIceLock",
  RELEASES_ICE_TIMER_LOCK: "releasesIceTimerLock",
  GPS_LOCK: "gpsLock",
};

export const USER_TYPE = {
  PARENT: "parent",
  CHILD: "child",
  PARENT_IN_CHILD: "parentInChild",
  CHILD_IN_PARENT: "childInParent",
  PACKAGE_USER: "packageUser",
  PACKAGE_IN_MODERATOR: "packageInModerator",
  PACKAGE_MODERATOR: "packageModerator",
};

export const TYPE_OF_STATUS = {
  PENDING: "PENDING",
  REQUESTED: "REQUESTED",
  APPROVED: "APPROVED",
  DENIED: "DENIED",
};

export const HIDE_SHOW_FLAG_NAME = {
  PARENT: "parent",
  KID: "kid",
  MODERATOR: "moderator",
};

export const regExs = {
  regExpPrivateLinkTg:
    /^(http|https|Https):\/\/(t|T).(m|M)e\/(joinchat\/|Joinchat\/|join\/Join\/)(.*)$/,
  regExpLinkTg: /^(http|https|Https):\/\/(t|T|telegram).(m|M)e\/(.*)$/,
  regExpLinkTgOld:
    /^(http|https|Https):\/\/(t|T|telegram).(m|M)e\/(joinchat\/|Joinchat\/|join\/|Join\/|)(.*)$/,
  regExpStartWithAt: /^@(.*)/,
  regExpStartWithoutHttp: /^(t|T|telegram).(m|M)e\/(.*)$/,
};

export const scheduleList = [
  { value: 1, label: "1", className: "", default: false },
  { value: 2, label: "2", className: "", default: false },
  { value: 3, label: "3", className: "", default: false },
  { value: 4, label: "4", className: "", default: false },
  { value: 5, label: "5", className: "", default: false },
  { value: 6, label: "6", className: "", default: false },
  { value: 7, label: "7", className: "", default: false },
  { value: 8, label: "8", className: "", default: false },
  { value: 9, label: "9", className: "", default: false },
  { value: 10, label: "10", className: "", default: false },
  { value: 11, label: "11", className: "", default: false },
  { value: 12, label: "12", className: "", default: false },
  { value: 13, label: "13", className: "", default: false },
  { value: 14, label: "14", className: "", default: false },
  { value: 15, label: "15", className: "", default: false },
  { value: 16, label: "16", className: "", default: false },
  { value: 17, label: "17", className: "", default: false },
  { value: 18, label: "18", className: "", default: false },
  { value: 19, label: "19", className: "", default: false },
  { value: 20, label: "20", className: "", default: false },
  { value: 21, label: "21", className: "", default: false },
  { value: 22, label: "22", className: "", default: false },
  { value: 23, label: "23", className: "", default: false },
  { value: 24, label: "24", className: "", default: false },
  { value: "all", label: "All Day", className: "", default: false },
];

export const REASON_OF_ACTION = {
  INVALID_LINK: "inValidLink",
  ON_NIGHT_MODE: "onNightMode",
  PARENT_NIGHT_MODE_ON: "parentNightModeOn",
  UNDER_LOCK: "underLock",
  INVALID_KID: "inValidKid",
  KID_HAVE_ANTHER_PARENT: "kidHaveAntherParent",
  ADD_YOURSELF_AS_KID: "addYourselfAsKid",
  KID_ALREADY_EXISTS: "kidAlreadyExists",
};

export const TYPE_OF_USER = {
  CHILD: "child",
  PACKAGE_USER: "packageUser",
  SIMPLE_LOCK: "simpleLock",
  FREEZE_LOCK: "freeze",
  MELT: "melt",
  GPS_LOCK: "gpsLock",
  PARENT: "parent",
  PACKAGE_MODERATOR: "packageModerator",
};

export const TYPE_OF_LOCK = {
  SIMPLE_LOCK: "simple",
  START_FREEZE: "startFreeze",
  FREEZE: "melt",
  MELT: "freeze",
  GPS: "gps",
};

export const TIMES = {
  "2 mins": 2,
  "5 mins": 5,
  "1 hr": 1,
  "3 hrs": 3,
  "6 hrs": 6,
  "24 hrs": 24,
  "2 Days": 2,
  "7 days": 7,
  "30 days": 30,
  NO_FREEZE: "noFreeze",
};

export const LS_VARIABLE = {
  IDS: "ids",
  I18_NEXT_LANGUAGE: "i18nextLng",
  LOGGED_USER_INFO: "loggedUserInfo",
  DEVICE: "device",
  FCM_TOKEN: "FCMToken",
};

import {
  premiumAccess,
  premiumAds,
  premiumFutureDev,
  premiumPhoto,
} from "../../Containers/NewLandingPage/imports";

let features = [
  {
    title: "FeaturePremiumTitle1_i18n",
    image: premiumAccess,
  },
  {
    title: "FeaturePremiumTitle2_i18n",
    image: premiumAds,
  },
  {
    title: "FeaturePremiumTitle3_i18n",
    image: premiumFutureDev,
  },
  {
    title: "FeaturePremiumTitle4_i18n",
    image: premiumPhoto,
  },
];

const featuresPremium = () => {
  return { features };
};

export default featuresPremium;

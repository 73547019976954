/* eslint-disable react-hooks/rules-of-hooks */
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getLanguageCountry } from "../../../shared/CommanFunction";

export default function diamond() {
  const { userInfo } = useSelector((state) => state.users);
  const userSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.id]
  );
  const [data, setData] = useState({
    id: userInfo?.id,
    language: userSettingInfo?.language
      ? getLanguageCountry(userSettingInfo?.language)?.toLocaleLowerCase()
      : "us",
  });
  const history = useHistory();
  const handleChange = (e) => {
    var { name, value } = e.target;
    var tempName;
    tempName = name === "remarks" ? "id" : "language";
    value = name === "language" ? value?.toLocaleLowerCase() : value;
    console.log("{ name, value } :>> ", { tempName, value });
    setData({ ...data, [tempName]: value });
  };
  const handleSubmit = () => {
    // window.location.replace("https://direct.tranzila.com/teleteens/");
    history.push("/diamond-step-2");
  };
  return {
    handleSubmit,
    userInfo,
    data,
    handleChange,
  };
}

let questions = [
  {
    question: 'FAQQuestion1_i18n',
    answer: 'FAQAnswer1_i18n'
  },
  {
    question: 'FAQQuestion2_i18n',
    answer: 'FAQAnswer2_i18n'
  },
  {
    question: 'FAQQuestion3_i18n',
    answer: 'FAQAnswer3_i18n'
  },
  {
    question: 'FAQQuestion4_i18n',
    answer: 'FAQAnswer4_i18n'
  },
  {
    question: 'FAQQuestion5_i18n',
    answer: 'FAQAnswer5_i18n'
  }
];

const faq = () => {
  return { questions };
};

export default faq;

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

//This file is for manage the flag of "Manage Kid" setting.

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const manageKid = ({ handleCallActions, userId, userInfo, userSettingInfo }) => {
  const [manageKidFlag, setManageKidFlag] = useState(true);
  const parentSettingInfo = useSelector((state) => state.users.userList[userInfo?.id]);

  useEffect(() => {
    parentSettingInfo?.childDontAccessIds.includes(userId)
      ? setManageKidFlag(false)
      : setManageKidFlag(true);
  }, [userSettingInfo, parentSettingInfo]);

  //For manage the flag change event.
  const handleUpdateManageKid = (name) => {
    handleCallActions(userId, name);
  };

  return { manageKidFlag, handleUpdateManageKid };
};

export default manageKid;

/* eslint-disable react-hooks/rules-of-hooks */
//It is add kid functionality, user can add kid by giving valid kid id.

import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import db from "../../../config/firebase";
import {
  TYPE_OF_ACTION,
  TYPE_OF_STATUS,
  REASON_OF_ACTION,
} from "../../../description/constant";

export default function addKid() {
  const { t } = useTranslation();
  const [id, setId] = useState();
  const [childIds, setChildIds] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [message, setMessage] = useState("");
  const [typeOfAlert, setTypeOfAlert] = useState("");
  const { selectedId, userInfo, isPremium, loginStatus } = useSelector(
    (state) => state.users
  );
  const userSettingInfo = useSelector(
    (state) => state.users.userList[selectedId]
  );
  const { children } = userSettingInfo;

  // get child ids in array
  useEffect(() => {
    if (children && children.length) {
      setChildIds(children?.map((child) => child?.childId?.toString()));
    } else {
      setChildIds([]);
    }
  }, [userSettingInfo]);

  useEffect(() => {
    getUpdatedStatus();
  }, [loader]);

  // It is function for getting the status of action which is currently executed by user and set error or success message accordingly.
  const getUpdatedStatus = () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`;
    db.collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("actionType", "==", `${TYPE_OF_ACTION.PARENT_ADD_KID}:${id}`)
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length) {
          setLoader(true);
          getUpdatedStatus();
        } else {
          setLoader(false);
          isSubmit &&
            db
              .collection(
                `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`
              )
              .where(
                "actionType",
                "==",
                `${TYPE_OF_ACTION.PARENT_ADD_KID}:${id}`
              )
              .get()
              .then((snapshot) => {
                if (snapshot && snapshot.docs.length) {
                  const { status, reason } = snapshot.docs
                    .map((doc) => doc.data())
                    .pop();
                  if (status === TYPE_OF_STATUS.DENIED) {
                    reason === REASON_OF_ACTION.INVALID_KID &&
                      setAlert(t(`inValidKid_i18n`), "error");
                    reason === REASON_OF_ACTION.KID_HAVE_ANTHER_PARENT &&
                      setAlert(t(`kidHaveAntherParent_i18n`), "error");
                    reason === REASON_OF_ACTION.UNDER_LOCK &&
                      setAlert(t(`underLock_i18n`), "error");
                    reason === REASON_OF_ACTION.ADD_YOURSELF_AS_KID &&
                      setAlert(t(`addYourselfAsKid_i18n`), "error");
                    reason === REASON_OF_ACTION.KID_ALREADY_EXISTS &&
                      setAlert(t(`isAlreadyYourKid_i18n`), "error");
                  } else {
                    setAlert(t(`requestSentToKid_i18n`), "success");
                  }
                }
              });
          setIsSubmit(false);
        }
      });
  };

  const setAlert = async (message, type) => {
    await setTypeOfAlert(type);
    await setMessage(message);
  };

  const onChange = (e) => {
    setId(e.target.value);
  };

  // It is onSubmit function, it pass the action if kid id is valid and not already kid.
  const onSubmit = (e) => {
    e.preventDefault();
    setLoader(true);
    if (childIds.includes(id.toString())) {
      setAlert(t(`isAlreadyYourKid_i18n`), "error");
      setIsSubmit(false);
    } else {
      const date = new Date().getTime();
      const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions/${date} - ${TYPE_OF_ACTION.PARENT_ADD_KID}`;
      db.doc(databaseUrl).set({
        userId: userInfo?.id,
        actionId: `${date}-${TYPE_OF_ACTION.PARENT_ADD_KID}`,
        actionType: `${TYPE_OF_ACTION.PARENT_ADD_KID}:${id}`,
        status: TYPE_OF_STATUS.PENDING,
        createdTime: new Date(),
        name: TYPE_OF_ACTION.PARENT_ADD_KID,
      });
      setIsSubmit(true);
    }
  };

  //For automatically close the alert after 5 seconds and clearing the field value.
  message &&
    setTimeout(() => {
      setMessage("");
      setTypeOfAlert("");
      // document.querySelector(`input[type="number"]`).value = "";
      setId();
    }, 3000);

  //For closing the alert notifications and clearing the field value.
  const handleCloseAlert = () => {
    setMessage("");
    setTypeOfAlert("");
    // document.querySelector(`input[type="number"]`).value = "";
    setId();
  };

  //It is function for preventing the user from enter special characters.
  const onKeyPress = (evt) => {
    if (
      (evt.which !== 8 && evt.which !== 0 && evt.which < 48) ||
      evt.which > 57
    ) {
      evt.preventDefault();
    }
  };

  const flag = loader ? true : id ? false : true;

  const fields = [
    {
      type: "number",
      className: "form-control",
      onChange: onChange,
      onKeyPress: onKeyPress,
      required: true,
    },
    {
      type: "submit",
      className: "ms-2 btn btn-primary",
      disabled: flag,
      value: t("sendButton_i18n"),
    },
  ];

  return {
    message,
    typeOfAlert,
    handleCloseAlert,
    onSubmit,
    loader,
    fields,
    isPremium,
    loginStatus,
  };
}

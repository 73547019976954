import React from "react";
import { useTranslation } from "react-i18next";
import {
    windowsIcon,
    heroLogo,
    appStoreIcon,
    googlePlayIcon,
    heroImage,
} from "../../Containers/NewLandingPage/imports";
import { useSelector } from "react-redux";
import DownloadSubMenu from './DownloadSubMenu.jsx';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';


const Hero = () => {
    const { t } = useTranslation();
    const downloadLinks = useSelector((state) => state.users.downloadLinks);

    return (
        <div className="hero">
            <div className="container">
                <div className="hero__inner">
                    <div className="hero__content">
                        <div className="hero__logo">
                            <img src={heroLogo} alt="" />
                        </div>
                        <div className="hero__info">
                            <h2 className="hero__title">{t("HeroTitle_i18n")}</h2>
                            <p className="hero__text">{t("HeroText_i18n")}</p>
                        </div>
                        <div className="hero__apps">
                            <ul className="hero__apps-list">
                                <li className="hero__apps-item">
                                    <a
                                        href="https://apps.apple.com/mn/app/teleteens/id1516268121"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            className="hero__apps-icon"
                                            src={appStoreIcon}
                                            alt="Apple store"
                                        />
                                    </a>
                                    <span className="hero__apps-title">iOS</span>
                                </li>
                                <li className="hero__apps-item">
                                    <OverlayTrigger
                                        trigger={["click"]}
                                        placement="right"
                                        rootClose
                                        overlay={
                                            <Popover id="popover-basic">
                                                <DownloadSubMenu
                                                    className="download__submenu_hero"
                                                    items={downloadLinks?.android_download_links}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <img
                                            className="hero__apps-icon"
                                            src={googlePlayIcon}
                                            alt="Google Play"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </OverlayTrigger>
                                    <span className="hero__apps-title">Android</span>
                                </li>
                                <li className="hero__apps-item ">
                                    <OverlayTrigger
                                        trigger={["click"]}
                                        rootClose
                                        placement="right"
                                        overlay={
                                            <Popover id="popover-basic">
                                                <DownloadSubMenu
                                                    className="download__submenu_hero"
                                                    items={downloadLinks?.windows_download_links}
                                                />
                                            </Popover>
                                        }
                                    >
                                        <img
                                            className="hero__apps-icon"
                                            src={windowsIcon}
                                            alt="Microsoft store"
                                            style={{ cursor: "pointer" }}
                                        />
                                    </OverlayTrigger>

                                    <span className="hero__apps-title ">
                                        Windows
                                    </span>
                                    {/* <span className="label--soon">{t("SoonLable_i18n")}</span> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="hero__image">
                        <img src={heroImage} alt="" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;

import React, { useEffect, useState } from "react";
import LoginBtn from "./ui/LoginBtn.jsx";
import LanguageSwitcher from "./ui/LanguageSwitcher.jsx";

const HeaderActions = () => {
  const [isMobile, setIsMobile] = useState(false);

  const handleResize = () => {
    if (window.innerWidth < 728) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener("load", handleResize);
    window.addEventListener("resize", handleResize);
  });

  return (
    <div className="actions">
      <LoginBtn className="d-none d-md-block" />
      <LanguageSwitcher />
    </div>
  );
};

export default HeaderActions;

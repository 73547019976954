import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import manageKid from "./../../Containers/User/ManageKid";
import { useTranslation } from "react-i18next";

export default function ManageKid({
  handleCallActions,
  userId,
  handleDisabledCheckBox,
  userInfo,
  userSettingInfo,
}) {
  const { t } = useTranslation();
  const { manageKidFlag, handleUpdateManageKid } = manageKid({
    handleCallActions,
    userId,
    userInfo,
    userSettingInfo,
  });

  return (
    <>
      <h3 className="mb-3">{t("manageKidTitle_i18n")}</h3>
      <div className="mx-2 setting d-flex">
        <div className="name-label">{`${t(
          "manageKidFlag_i18n"
        )} (${userId})`}</div>
        <div className="switch-button">
          <div className="form-check form-switch">
            <input
              checked={manageKidFlag}
              onChange={() => handleUpdateManageKid("manageKidAccess")}
              className={`form-check-input ${
                handleDisabledCheckBox("manageKidAccess")
                  ? "disabled-cursor disabled-event"
                  : "cursor-pointer"
              }`}
              type="checkbox"
              role="switch"
              id="flexSwitchCheckChecked"
              disabled={handleDisabledCheckBox("manageKidAccess") || false}
            />
            <label
              className="form-check-label"
              htmlFor="flexSwitchCheckChecked"
            ></label>
          </div>
          {handleDisabledCheckBox("manageKidAccess") && (
            <Spin indicator={<LoadingOutlined />}></Spin>
          )}
        </div>
      </div>
      <hr />
    </>
  );
}

import React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Avatar, Image } from "antd";
import sidebar from "./../../Containers/User/Sidebar";
import {
  componentList,
  sidebarIcon,
} from "./../../../description/constant";
import { handleSidebarTitle } from "../../../shared/CommanFunction";

export default function Sidebar({ addClassFlag, toggle, t }) {
  const { pathname, profile, selectedCategory, push } = sidebar();
  return (
    <div>
      <div
        className={`b-sidebar-backdrop ${addClassFlag && "show"}`}
        onClick={toggle}
      ></div>
      <div
        className={`SideBar d-flex flex-column flex-shrink-0 text-black bg-light 
        ${addClassFlag && "show"}`}
      >
        <ul
          className="nav nav-pills sidebar_menu flex-column mb-auto p-0"
          id="pills-tab"
          role="tablist"
        >
          <li className="nav-item profile-button" role="presentation">
            <div
              className={`nav-link sidebar_title bg-transparent ${
                pathname.replace("/", "") === "home" && "active"
              }`}
              id="home-lists-tab"
              data-bs-toggle="pill"
              data-bs-target="#home-lists"
              type="button"
              role="tab"
              aria-controls="home-lists"
              aria-selected={
                pathname.replace("/", "") === "home" ? true : false
              }
            >
              {profile && profile?.photo_url ? (
                <Avatar
                  src={
                    <Image
                      src={profile?.photo_url}
                      style={{
                        backgroundColor: "#01579b",
                        verticalAlign: "middle",
                      }}
                    />
                  }
                >
                  {" "}
                </Avatar>
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#01579b",
                    verticalAlign: "middle",
                  }}
                >
                  {(profile?.first_name &&
                    profile?.first_name.trim().charAt(0)) || <UserOutlined />}
                </Avatar>
              )}
              <div>
                <p className="profile-name">
                  {(profile?.first_name && profile?.first_name) ||
                    t("none_i18n")}
                </p>
                <p className="profile-id">{profile?.id || ""}</p>
              </div>
            </div>
          </li>
          {componentList[selectedCategory].map((el, i) => {
            return (
              <li
                key={i}
                className={
                  el === "approval-lists" ? "nav-item mt-3" : "nav-item"
                }
                role="presentation"
                onClick={() => {
                  toggle();
                  return push(`/${el}`);
                }}
              >
                <button
                  className={`nav-link ${
                    pathname.replace("/", "") === el && "active"
                  }`}
                  id={`${el}-tab`}
                  data-bs-toggle="pill"
                  data-bs-target={`#${el}`}
                  type="button"
                  role="tab"
                  aria-controls={el}
                  aria-selected={
                    pathname.replace("/", "") === el ? true : false
                  }
                >
                  {" "}
                  {sidebarIcon[el]}
                  {/* <i className={classNames[el]}></i>{" "} */}
                  <h6>{handleSidebarTitle(el, t)}</h6>
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

/* eslint-disable react-hooks/rules-of-hooks */
//By click on the notification icon, user redirect to this page.
//Here user can see all the list of notifications.
//User also accept or deny the request of that particular notifications.
import { useState, useEffect, useRef } from "react";
import db from "../../../config/firebase";
// import useInfiniteScroll from "react-infinite-scroll-hook";
// import { BottomScrollListener } from "react-bottom-scroll-listener";
// import moment from "moment";
// import {useSelector } from 'react-redux'
// import useOnScreen from "../../hooks/use-on-screen";
// import LazyLoad from 'react-lazy-load'
import { useDispatch, useSelector } from "react-redux";
import { TYPE_OF_STATUS } from "../../../description/constant";

function notifications() {
  const myRef = useRef(null);
  const scrollDiv = useRef(null);
  const multipleRef = useRef();
  // const timelineRef = useRef();
  const { userInfo, isPremium, loginStatus } = useSelector(
    (state) => state.users
  );
  const parentSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.id]
  );
  multipleRef.current = [];
  const prevScrollY = useRef(0);
  const [notification, setNotification] = useState();
  const [goingUp, setGoingUp] = useState(false);
  const [currentDoc, setCurrentDoc] = useState(1);
  const [snapShotFlag, setSnapShotFlag] = useState(true);
  const dispatch = useDispatch();
  const button = ["deny", "accept"];
  const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/notification`;

  // const onScreen= useOnScreen(multipleRef.current, "0px")
  // const onScreen1 = useOnScreen(multipleRef);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const date = new Date().getTime();

  //It is function for pass the action which is executed by user from clicking on the accept or deny button.
  const handleRequest = (type, id, notificationId, request, acceptStatus) => {
    const docId = notificationId.split("_")[0];
    db.doc(
      `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/notification/${docId}`
    ).update({ [`notification.${notificationId}.status`]: `${acceptStatus}` });
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions/${date} - ${type}`;
    const payload = {
      userId: userInfo?.id,
      actionId: `${date}-${type}`,
      actionType: `${type}:${id}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      name: type,
      request: request || "",
      // reason: ""
    };
    if (!request) {
      delete payload.request;
    }
    db.doc(databaseUrl).set(payload);
  };

  //Function for showing the single notification message and button according to type of notification

  useEffect(() => {
    setTimeout(() => {
      myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }, 3000);
  }, []);

  useEffect(() => {
    dispatch({
      type: "SET_NOTIFICATION",
      payload: notification?.notification,
    });
  }, [notification]);

  // const addToRefs = (el) => {
  //   if (el && !multipleRef.current.includes(el)) {
  //     multipleRef.current.push(el);
  //   }
  // };
  // Display all the notifications by mapping on notification array.

  useEffect(() => {
    if (notification?.notification?.length && snapShotFlag) {
      db.collection(`${databaseUrl}`)
        .where(
          "uniqueId",
          "in",
          parentSettingInfo?.notificationsId
            .sort((a, b) => a - b)
            .filter(
              (el, i) =>
                i > parentSettingInfo?.notificationsId?.length - currentDoc - 1
            )
        )
        .onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (
              parentSettingInfo?.notificationsId
                .sort((a, b) => a - b)
                .filter(
                  (el, i) =>
                    i >
                    parentSettingInfo?.notificationsId?.length - currentDoc - 1
                )
                .includes(Number(change.doc.id))
            ) {
              const temp = Object.values(change.doc.data()?.notification)?.sort(
                (a, b) => a.createdAt.seconds - b.createdAt.seconds
              );
              const remove = notification?.notification?.filter(
                (el) =>
                  el?.notificationId.split("_")[0] !==
                  temp[0]?.notificationId.split("_")[0]
              );
              const merge = [...remove, ...temp]?.sort(
                (a, b) => a.createdAt.seconds - b.createdAt.seconds
              );
              merge.length && handleNotifications(merge);
            }
          });
        });
      setSnapShotFlag(false);
    }
    // },[parentSettingInfo?.notificationsId.sort((a, b) =>a-b)[parentSettingInfo?.notificationsId.length - currentDoc],notification?.notification?.length])
  }, [notification?.notification, snapShotFlag]);

  useEffect(() => {
    if (parentSettingInfo?.notificationsId?.length) {
      const ff = parentSettingInfo?.notificationsId.sort((a, b) => a - b)[
        parentSettingInfo?.notificationsId.length - currentDoc
      ];
      if (ff) {
        getNotifications(ff);
      }
    } else {
      setNotification({ ...notification, notification: [] });
    }
  }, [currentDoc]);

  // useEffect(() => {
  //  return () => handleSetSeen(userInfo?.id,notification?.notification)
  // },[])

  // const handleSetSeen = (id,notifications) => {
  //   const temp = notifications?.map((el)=>{return ({...el,isSeen: true})})
  //   const result = temp?.reduce((r,e)=>{
  //     var k = e.notificationId.split("_")[0]
  //     if (r[k] || (r[k]=[])) r[k].push(e)
  //     return r;
  //   },{})
  //   result && Object.entries(result)?.length && Object.entries(result)?.forEach(([key, val]) => {
  //     var temp2 = {}
  //     val?.map(el => temp2[el.notificationId] = el)
  //     db.doc(`${process.env.REACT_APP_FIREBASE_COLLECTION}/${id}/notification/${key.toString()}`).update({notification: temp2})
  //   })
  // };

  const getNotifications = async (id) => {
    const notifications = (await db.doc(`${databaseUrl}/${id}`).get()).data()
      ?.notification;
    // const snapshot = id && notification?.length && Object.values(
    const snapshot = id && notifications && Object.values(notifications);
    // const snapshot = id && Object.values(
    //   (await db.doc(`${databaseUrl}/${id}`).get()).data()?.notification
    // );
    // snapshot.length < 6 && await setCurrentDoc(currentDoc+1)
    const temp =
      snapshot && notification?.notification
        ? [
            ...snapshot?.sort(
              (a, b) => a.createdAt.seconds - b.createdAt.seconds
            ),
            ...notification?.notification,
          ]
        : snapshot?.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds);
    // : snapshot.length < 6 ? [...Object.values((await db.doc(`${databaseUrl}/${parentSettingInfo?.notificationsId.sort((a, b) =>a-b)[parentSettingInfo?.notificationsId.length - currentDoc -1]}`).get()).data()?.notification),...snapshot] : snapshot?.sort((a, b) =>a.createdAt.seconds-b.createdAt.seconds)
    const tempNotification = snapshot?.length && [
      ...snapshot?.sort((a, b) => a.createdAt.seconds - b.createdAt.seconds),
    ];
    if (notification?.notification) {
      tempNotification?.push(...notification?.notification);
    }
    notification?.notification
      ? setNotification({
          notification: tempNotification,
          unReadMessageIndex: 0,
          // scrollInto: 0,
        })
      : handleNotifications(temp);
    notification?.notification && setSnapShotFlag(true);

    // const temp = snapshot && snapshot?.sort((a, b) =>a.createdAt.seconds-b.createdAt.seconds)
    temp && temp.length && handleNotifications(temp);
    // scrollSmoothHandler();
  };

  // const scrollSmoothHandler = () => {
  //   if (scrollDiv?.current) {
  //     // var element = document.getElementById('unread');
  //     // var headerOffset = 58;
  //     // var elementPosition = element.getBoundingClientRect().top;
  //     // var offsetPosition = elementPosition - headerOffset;
  //     // window.scrollTo({
  //     //     top: offsetPosition
  //     // });
  //   }
  // };

  const handleNotifications = (temp) => {
    const index = temp && temp?.findIndex((x) => !x.isSeen);
    let scrollInto = temp?.length - 1;
    let unReadMessageIndex = null;
    if (~index) {
      unReadMessageIndex = index;
      scrollInto = index;
    }
    setNotification({
      notification: temp,
      unReadMessageIndex,
      // scrollInto,
    });
  };

  const nextCall = () => {
    // if(parentSettingInfo?.notificationsId.length >= currentDoc + 1){
    setCurrentDoc(currentDoc + 1);
    // }
  };

  return {
    myRef,
    nextCall,
    currentDoc,
    parentSettingInfo,
    multipleRef,
    handleRequest,
    button,
    notification,
    isPremium,
    loginStatus,
  };
}

export default notifications;

export const toolTip = (t, id, name, parent, child, time) => {
  return {
    fingerprint: [`Your Telegram ID is ${id}`, `Your name is ${name}`],
    parent: [`You have the following parents: ${parent || t("none_i18n")}`],
    child: [`You have the following kids: ${child || t("none_i18n")}`],
    clock: [`Your current time time is: ${time}`],
    dollar: [t("cantBuyPremium_i18n")],
    plusPerson: [t(`canAddMoreKids_i18n`)],
    thumbsUp: [t("toolTip1_i18n"), t("toolTip2_i18n"), t("toolTip3_i18n")],
    childNotAllow: [t("toolTip4_i18n")],
    dissatisfiedNotAllow: [t("toolTip5_i18n")],
    acUnit: [t("toolTip6_i18n"), t("toolTip7_i18n")],
    waterDrop: [t("toolTip8_i18n")],
    location: [t("toolTip6_i18n"), t("toolTip9_i18n")],
    locationUnlock: [t("toolTip10_i18n")],
    unlock: [t("toolTip6_i18n"), t("toolTip11_i18n")],
    lock: [t("toolTip12_i18n")],
    bulb: [t("toolTip13_i18n")],
    childRelation: [t("toolTip14_i18n")],
    dissatisfied: [t("toolTip15_i18n")],
    lockFree: [t("toolTip16_i18n")],
    childFree: [t("toolTip14_i18n")],
    dissatisfiedFree: [t("toolTip15_i18n")],
    waterDropMelted: [t("toolTip17_i18n"), t("toolTip18_i18n")],
    bulbMelted: [t("toolTip19_i18n")],
    acUnitMelted: [t("toolTip20_i18n")],
    face: [t("toolTip21_i18n")],
    childKidNotAllow: [t("toolTip22_i18n")],
    thumbsDown: [t("toolTip23_i18n"), t("toolTip24_i18n")],
    dissatisfiedKid: [t("toolTip25_i18n")],
    childKid: [t("toolTip26_i18n")],
    appBlockPackageUser: [t("toolTip27_i18n")],
    thumbsUpPackageUser: [t("toolTip28_i18n")],
    sendToMobile: [t("toolTip29_i18n")],
    appBlockModerator: [t("toolTip30_i18n")],
    thumbsUpModerator: [t("toolTip31_i18n")],
    triangle: [t("toolTip32_i18n")],
  };
};

import React from "react";
import { useTranslation } from "react-i18next";
import {
  cooperationLogo1,
  cooperationLogo2,
  cooperationLogo3,
} from "../../Containers/NewLandingPage/imports";

const Cooperation = () => {
  const { t } = useTranslation();

  return (
    <div className="cooperation">
      <div className="container">
        <div className="cooperation__inner">
          <h3 className="cooperation__title">{t("CooperationTitle_i18n")}</h3>
          <div className="cooperation__items">
            <div className="cooperation__item">
              <img src={cooperationLogo1} alt="" />
            </div>
            <div className="cooperation__item">
              <img src={cooperationLogo2} alt="" />
            </div>
            <div className="cooperation__item">
              <img src={cooperationLogo3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cooperation;

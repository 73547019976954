/* eslint-disable react-hooks/rules-of-hooks */
//This is the header file, it shows the download, features, about, contact, what is teleteens, language options and login button.
//After click on login button user redirects to "index.js" file of "User" folder, where user see the "My account", childs and parents setting list.

import { useEffect, useState } from "react";
import db from "../../../config/firebase";
import { useRouter } from "../../../hooks/use-router";
import { useDispatch, useSelector } from "react-redux";
import { LS_VARIABLE } from "../../../description/constant";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../../../shared/CommanFunction";

let data = [
  {
    label: "Download_i18n",
    link: "download",
  },
  {
    label: "whatIsIt_i18n",
    link: "whatIsIt",
  },
  {
    label: "Features_i18n",
    link: "features",
  },
  {
    label: "Cooporation_i18n",
    link: "cooporation",
  },
  {
    label: "Contact_i18n",
    link: "contact",
  },
  {
    label: "About_i18n",
    link: "about",
  },
];

function header({ handleAddClass, addClassFlag }) {
  const { t } = useTranslation();
  const [notification, setNotification] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const language = localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE);
  const { push, pathname } = useRouter();
  const dispatch = useDispatch();
  const { userInfo, notifications, loginStatus } = useSelector(
    (state) => state.users
  );
  const parentSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.id]
  );
  useEffect(() => {
    dispatch({
      type: "LOCATION",
      payload:
        pathname === "/" ||
        pathname === "/faq" ||
        pathname === "/support" ||
        pathname === "/premium" ||
        pathname === "/i-doa" ||
        pathname === "/login",
    });
  }, [pathname]);

  useEffect(() => {
    changeLanguage(parentSettingInfo?.language || "en", i18next);
  }, [parentSettingInfo]);

  useEffect(() => {
    const fetchData = async () => {
      const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/notification`;
      db.doc(
        `${databaseUrl}/${
          parentSettingInfo?.notificationsId?.sort((a, b) => a - b)[
            parentSettingInfo?.notificationsId?.length - 1
          ]
        }`
      ).onSnapshot((snapshot) => {
        snapshot &&
          snapshot?.data()?.notification &&
          setNotification(
            Object.values(snapshot?.data()?.notification)?.filter(
              (el) => el.isSeen === false
            ).length
          );
      });
    };
    parentSettingInfo?.notificationsId?.length && fetchData();
  });

  useEffect(() => {
    const header = document.querySelector("header");
    document.addEventListener("scroll", () => {
      var scroll_position = window.scrollY;
      if (scroll_position > 20) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
  }, [userInfo]);

  useEffect(() => {
    const userInfo = JSON.parse(
      localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)
    );
    dispatch({
      type: "SET_USER_DATA",
      payload: userInfo ? userInfo : {},
    });
    dispatch({
      type: "SET_LOGIN_FLAG",
      payload: userInfo ? true : false,
    });
  }, []);

  // const handleSelect = (event) => {
  //   document.body.setAttribute(
  //     "dir",
  //     event.target.value === "en" ? "ltr" : "rtl"
  //   );
  //   changeLanguage(event.target.value);
  // };

  const toggle = () => setIsOpen(!isOpen);

  const handleLogout = async () => {
    dispatch({
      type: "PURGE",
    });
    localStorage.removeItem(LS_VARIABLE.LOGGED_USER_INFO);
    localStorage.removeItem(LS_VARIABLE.DEVICE);
    localStorage.removeItem(LS_VARIABLE.IDS);
    dispatch({
      type: "SET_LOGIN_FLAG",
      payload: false,
    });
    dispatch({
      type: "SET_USER_DATA",
      payload: {},
    });
  };

  const handleOpenUserDetails = () => {
    push("/user-list");
  };

  const handleAddClassName = () => {
    toggle();
  };

  const handleHideMenu = () => {
    addClassFlag && handleAddClass();
  };

  return {
    pathname,
    handleHideMenu,
    toggle,
    data,
    t,
    i18next,
    isOpen,
    // handleSelect,
    // language,
    loginStatus,
    handleAddClassName,
    handleLogout,
    notification,
    push,
    handleOpenUserDetails,
    userInfo,
    notifications,
  };
}

export default header;

import React, { useState, useEffect } from "react";
import { arrowDown } from "./../../Containers/NewLandingPage/imports";
import Downloads from "./Downloads.jsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Navbar = ({ close }) => {
    const { t } = useTranslation();

    const [isOpen, setOpen] = useState(false);
    const toggleDownloads = (e) => {
        if ((e.target.target = "_blank") && !isOpen) setOpen(true);
        else if (isOpen) setOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            const classList = event.target.classList.value.split(" ")
            if (!(classList.includes("nav__link--download") || classList.includes("arrow-down"))) {
                setOpen(false);
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isOpen]);



    return (
        <nav className="nav">
            <ul className="nav__list">
                <li className="nav__item nav__item--download">
                    <span
                        className="nav__link nav__link--download"
                        onClick={toggleDownloads}
                    >
                        {t("Download_i18n")}
                        <img
                            className={isOpen ? "arrow-down active" : "arrow-down"}
                            src={arrowDown}
                            alt=""
                        />
                        {isOpen ? <Downloads className="download-main" /> : ""}
                    </span>
                </li>
                <li className="nav__item">
                    <Link onClick={close} to="/premium" className="nav__link">
                        {t("Premium_i18n")}
                    </Link>
                </li>
                <li className="nav__item">
                    <Link onClick={close} to="/faq" className="nav__link">
                        {t("FAQ_i18n")}
                    </Link>
                </li>
                <li className="nav__item">
                    <Link onClick={close} to="/support" className="nav__link">
                        {t("Support_i18n")}
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;

import React from 'react';

const FeatureItem = ({ title, text, img }) => {
  return (
    <div className="feature">
      <div className="feature__content">
        <h4 className="feature__title">{title}</h4>
        <p className="feature__text">{text}</p>
      </div>
      <div className="feature__image">
        <img src={img} alt="" />
      </div>
    </div>
  );
};

export default FeatureItem;

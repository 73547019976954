//This file is run after index file.
//In this files all routes are defined.
//Onsnapshot method is defined in this file.
//After this file, "Header.js" file of "LandingPage" folder will running on top.

import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "antd/dist/antd.css";
import HomePage from "./component/Presentation/LandingPage/HomePage.jsx";
import Header from "./component/Presentation/LandingPage/Header.jsx";
import NewHeader from "./component/Presentation/NewLandingPage/Header.jsx";
import Lobby from "./component/Presentation/User/Lobby.jsx";
import WhoAmI from "./component/Presentation/User/WhoAmI.jsx";
import Notification from "./component/Presentation/User/Notification.jsx";
import AddChat from "./component/Presentation/User/AddChat.jsx";
import AddKid from "./component/Presentation/User/AddKid.jsx";
import ApprovalList from "./component/Presentation/User/ApprovalLists.jsx";
import SelfLock from "./component/Presentation/User/SelfLock.jsx";
import Setting from "./component/Presentation/User/Settings.jsx";
import NightMode from "./component/Presentation/User/NightModeHours.jsx";
import Diamond from "./component/Presentation/User/Diamond.jsx";
import Logout from "./component/Presentation/User/Logout.jsx";
import LoginButton from "./component/Presentation/User/LoginButton.jsx";
import ScrollToTop from "./component/Presentation/NewLandingPage/ScrollToTop.jsx";
import Main from "./component/Presentation/NewLandingPage/Main.jsx";
import FAQView from "./component/Presentation/NewLandingPage/Pages/FAQView.jsx";
import SupportView from "./component/Presentation/NewLandingPage/Pages/SupportView.jsx";
import PremiumView from "./component/Presentation/NewLandingPage/Pages/PremiumView.jsx";
import LoginPage from "./component/Presentation/NewLandingPage/Pages/LoginPage.jsx";
import Deceleration from "./component/Presentation/NewLandingPage/Pages/Deceleration.jsx"
import db, { getToken, onMessageListener } from "./config/firebase";

import { useSelector, useDispatch } from "react-redux";
import { LS_VARIABLE } from "./description/constant";
import Language from "./component/Presentation/User/Language.jsx";
import { getConclusion } from "./shared/CommanFunction";
const App = () => {
    const userLists = useSelector((state) => state.users.userList);
    const { isHomePage, loginStatus, userInfo } = useSelector(
        (state) => state.users
    );
    const [addClassFlag, setAddClassFlag] = useState(false);
    // const ado = new Audio(
    //   "https://firebasestorage.googleapis.com/v0/b/webpage-teleteens.appspot.com/o/H42VWCD-notification.mp3?alt=media&token=e3da9e67-fa34-4fa7-96be-4335a1ba2d03"
    // );
    const dispatch = useDispatch();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");

    useEffect(() => {
        console.log(`calling...`);
        getToken();
        onMessageListener().then((payload) => {
            /**
             * Play sound for foreground notification
             */
            // ado.play();
            console.log("payload app js :>> ", payload);
        });
        if (loginStatus || localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE)) {
            document.body.setAttribute(
                "dir",
                ["en", "es", "hi", "ru", "pt", "uk"].includes(
                    localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE) || "en"
                )
                    ? "ltr"
                    : "rtl"
            );
            document.body.setAttribute(
                "class",
                ["en", "es", "hi", "ru", "pt", "uk"].includes(LS_VARIABLE.I18_NEXT_LANGUAGE || "en") ? "dir-ltr" : "dir-rtl"
            );
        }
    }, []);

    useEffect(() => {
        JSON.parse(localStorage.getItem(LS_VARIABLE.IDS))?.length &&
            handleSnapshots();
    }, [loginStatus]);

    const handleSnapshots = () => {
        db.collection(process.env.REACT_APP_FIREBASE_COLLECTION)
            .where("userId", "in", JSON.parse(localStorage.getItem(LS_VARIABLE.IDS)))
            .onSnapshot((snapshot) => {
                if (snapshot.empty) return;
                if (snapshot) {
                    snapshot.docChanges().forEach((change) => {
                        console.log("userLists :>> ", change.doc.data()?.userId);
                        if (
                            change.type === "modified" ||
                            change.type === "added" ||
                            change.type === "removed"
                        ) {
                            if (
                                JSON.parse(localStorage.getItem(LS_VARIABLE.IDS))?.includes(
                                    change.doc.data().userId
                                )
                            ) {
                                dispatch({
                                    type: "USER_LIST",
                                    payload: change.doc.data(),
                                });
                            }
                            if (
                                change.doc.data().userId ===
                                JSON.parse(localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO))
                                    ?.id
                            ) {
                                const conclusion = getConclusion(change.doc.data() || {});
                                dispatch({
                                    type: "IS_PREMIUM",
                                    payload: conclusion,
                                });
                                const tempChildIds = change.doc
                                    .data()
                                    ?.children?.filter((val) => val?.isConfirmed)
                                    ?.map((el) => {
                                        return el?.childId;
                                    });
                                const tempParentIds = change.doc
                                    .data()
                                    ?.parents?.filter((val) => val?.isConfirmed)
                                    ?.map((el) => {
                                        return el?.parentId;
                                    });
                                localStorage.setItem(
                                    LS_VARIABLE.IDS,
                                    JSON.stringify([
                                        ...new Set([
                                            change.doc.data().userId,
                                            ...tempChildIds,
                                            ...tempParentIds,
                                        ]),
                                    ])
                                );
                            }
                        }
                    });
                }
            });
    };

    useEffect(() => {
        const temp = userLists[userInfo?.id]?.children
            ?.filter((val) => val?.isConfirmed)
            ?.map((el) => {
                return el?.childId;
            });
        temp?.forEach((el) => {
            db.collection(process.env.REACT_APP_FIREBASE_COLLECTION)
                .doc(el?.toString())
                .onSnapshot((doc) => {
                    dispatch({
                        type: "USER_LIST",
                        payload: doc.data(),
                    });
                });
        });
        const temp1 = userLists[userInfo?.email]?.parents
            ?.filter((val) => val?.isConfirmed)
            ?.map((el) => {
                return el?.parentId;
            });
        temp1?.forEach((el) => {
            db.collection(process.env.REACT_APP_FIREBASE_COLLECTION)
                .doc(el?.toString())
                .onSnapshot((doc) => {
                    dispatch({
                        type: "USER_LIST",
                        payload: doc.data(),
                    });
                });
        });
    }, [userLists[userInfo?.id]]);

    useEffect(() => {
        db.collection("teleteens_website_misc").doc("download_links")
            .get()
            .then((snapshot) => {
                const data = snapshot.data();
                if (data) {
                    dispatch({
                        type: "DOWNLOAD_LINKS",
                        payload: data,
                    });
                }
            });
    }, []);

    return (
        <Router>
            {!token && (
                <>
                    {window.location.pathname === "/" || isHomePage ? (
                        <>
                            <NewHeader
                                handleAddClass={() => setAddClassFlag(!addClassFlag)}
                                addClassFlag={addClassFlag}
                            />
                        </>
                    ) : (
                        <Header
                            handleAddClass={() => setAddClassFlag(!addClassFlag)}
                            addClassFlag={addClassFlag}
                        />
                    )}
                </>
            )}
            <Suspense fallback="Loading...">
                <Switch>
                    <ScrollToTop>
                        <Route exact path="/" render={() => <Main />}></Route>
                        <Route path="/faq" render={() => <FAQView />}></Route>
                        <Route path="/support" render={() => <SupportView />}></Route>
                        <Route path="/premium" render={() => <PremiumView />}></Route>
                        <Route path="/i-doa" render={() => <Deceleration />}></Route>

                        {/* <Route exact path="/" render={() => <HomePage />} /> */}
                        <Route path="/user-list" render={() => <Lobby />} />
                        <Route path="/setting" render={() => <Setting />} />
                        <Route path="/night-mode" render={() => <NightMode />} />
                        <Route path="/approval-lists" render={() => <ApprovalList />} />
                        <Route
                            path="/global-approval-lists"
                            render={() => <ApprovalList />}
                        />
                        <Route path="/who-am-i" render={() => <WhoAmI />} />
                        <Route path="/add-kid" render={() => <AddKid />} />
                        <Route path="/notification" render={() => <Notification />} />
                        <Route path="/add-chat" render={() => <AddChat />} />
                        <Route path="/self-lock" render={() => <SelfLock />} />
                        <Route path="/diamond" render={() => <Diamond />} />
                        <Route path="/logout" render={() => <Logout />} />
                        <Route path="/secureConnection" render={() => <LoginButton />} />
                        <Route path="/login" render={() => <LoginPage />} />
                        <Route path="/language" render={() => <Language />} />
                    </ScrollToTop>
                </Switch>
            </Suspense>
        </Router>
    );
};

export default App;

import React from "react";
import { Link } from "react-scroll";
import {
  Button,
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
} from "reactstrap";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as NotificationIcon } from "../../../assets/images/header-icons-notification.svg";
import { ReactComponent as DiamondIcon } from "../../../assets/images/header-icons-diamondIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../assets/images/header-icons-logout.svg";
import { ReactComponent as TranslateIcon } from "../../../assets/images/header-icons-language.svg";
import header from "./../../Containers/LandingPage/Header";
import TelegramLoginButton from "react-telegram-login";
import Sidebar from "./../../Presentation/User/Sidebar.jsx";
import { Avatar } from "antd";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { handleSetSeen } from "../../../shared/CommanFunction";
import SidebarMenu from "../User/SidebarMenu.jsx";
import { showPageTitle } from "../../../description/constant";

export default function Header({ handleAddClass, addClassFlag }) {
  const { t } = useTranslation();
  const {
    pathname,
    handleHideMenu,
    toggle,
    data,
    isOpen,
    // handleSelect,
    // language,
    loginStatus,
    handleAddClassName,
    handleLogout,
    notification,
    push,
    handleOpenUserDetails,
    userInfo,
    notifications,
  } = header({ handleAddClass, addClassFlag });

  return (
    <>
      <header
        className={`header-wrap ${[
          "/",
          "/user-list",
          "/notification",
          "/diamond",
          "/logout",
          "/add-kid",
          "/language",
          "/diamond-step-2",
          "/login",
        ].includes(pathname)
          ? ""
          : "not-home"
          }`}
      >
        <Navbar fixed="top" light expand="md">
          <div
            className={pathname !== "/" ? "container-fluid" : "container"}
            onClick={handleHideMenu}
          >
            {pathname === "/" ? (
              <React.Fragment>
                <NavbarBrand>{t("Teleteens_i18n")}</NavbarBrand>
                <NavbarToggler
                  className={`${["he", "ar", "fa"].includes(i18next?.language)
                    ? "me-auto"
                    : "ms-auto "
                    }`}
                  onClick={toggle}
                />
                <Collapse
                  isOpen={isOpen}
                  navbar
                  className="justify-content-end"
                >
                  <Nav navbar>
                    {data.map((val, index) => (
                      <NavItem key={index} className="nav-item">
                        <Link
                          to={val.link}
                          spy={true}
                          smooth={true}
                          activeClass="active"
                          duration={200}
                          className="nav-link"
                        >
                          {t(`${val.label}`)}
                        </Link>
                      </NavItem>
                    ))}
                  </Nav>
                </Collapse>
                {/* <CustomSelect
                  className="ms-2"
                  onChange={handleSelect}
                  value={language || ""}
                >
                  <option value="en">English</option>
                  <option value="he">עברית</option>
                </CustomSelect> */}
                {!loginStatus ? (
                  <TelegramLoginButton
                    className="btn--login"
                    dataAuthUrl="https://teleteen.herokuapp.com/auth/user"
                    // dataAuthUrl="http://192.168.0.94:4000/auth/user"
                    // dataOnauth={handleTelegramResponse}
                    // botName="teleteens_Website_bot"
                    // botName="demoteleteens_bot"
                    botName="TeleteensBot"
                  />
                ) : (
                  <Button
                    className="ms-2 py-1 ml-4"
                    color="primary"
                    onClick={handleLogout}
                  >
                    {t("logout_i18n")}
                  </Button>
                )}
              </React.Fragment>
            ) : (
              ![
                "/add-kid",
                "/notification",
                "/user-list",
                "/diamond",
                "/logout",
                "/language",
                "/diamond-step-2",
              ].includes(pathname) && (
                <div className="page-heading">
                  {" "}
                  <Button
                    onClick={handleAddClassName}
                    type="button"
                    className="navbar-toggler bg-transparent border-0 p-0 toggle-white"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </Button>
                  <h4 className="page-title">{showPageTitle[pathname]}</h4>
                </div>
              )
            )}
            {[
              "/user-list",
              "/notification",
              "/diamond",
              "/logout",
              "/add-kid",
              "/language",
              "/diamond-step-2",
            ].includes(pathname) && (
                <div className="header-icon">
                  <div
                    className="notification-icon cursor-pointer"
                    onClick={() => {
                      return push(`/notification`);
                    }}
                  >
                    {/* <img
                    src={
                      pathname === "/notification"
                        ? BlueBellIcon
                        : NotificationIcon
                    }
                    alt=""
                  /> */}
                    <NotificationIcon />
                    <span
                      className={
                        notification ? "notification-count" : "empty-notification"
                      }
                      style={{
                        background: notification > 0 ? "red" : "grey"
                      }}
                    >
                      {notification <= 5 ? notification : `${5}+`}
                    </span>
                  </div>
                  <div
                    className="diamond-icon cursor-pointer mx-3"
                    onClick={() => {
                      pathname === "/notification" &&
                        handleSetSeen(userInfo?.id, notifications);
                      return push("/diamond");
                    }}
                  >
                    {/* <img
                    src={
                      pathname === "/diamond" || pathname === "/diamond-step-2"
                        ? BlueDiamond
                        : Diamond
                    }
                    alt=""
                  /> */}
                    <DiamondIcon />
                  </div>
                  <div
                    className="logout-icon cursor-pointer"
                    onClick={() => {
                      pathname === "/notification" &&
                        handleSetSeen(userInfo?.id, notifications);
                      return push("/logout");
                    }}
                  >
                    {/* <img
                    src={pathname === "/logout" ? BlueLogout : Logout}
                    alt=""
                  /> */}
                    <LogoutIcon />
                  </div>
                  <div
                    className="logout-icon cursor-pointer ms-3"
                    onClick={() => {
                      pathname === "/notification" &&
                        handleSetSeen(userInfo?.email, notifications);
                      return push("/language");
                    }}
                  >
                    {/* <img
                    src={pathname === "/language" ? TranslateBlue : Translate}
                    alt=""
                  /> */}
                    <TranslateIcon />
                  </div>
                </div>
              )}
            {loginStatus ? (
              userInfo && userInfo?.photo_url && userInfo?.photo_url ? (
                <Avatar
                  onClick={() => {
                    pathname === "/notification" &&
                      handleSetSeen(userInfo?.id, notifications);
                    return handleOpenUserDetails();
                  }}
                  src={userInfo?.photo_url}
                  style={{
                    backgroundColor: "#0dcaf0",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {" "}
                </Avatar>
              ) : (
                <Avatar
                  onClick={() => {
                    pathname === "/notification" &&
                      handleSetSeen(userInfo?.id, notifications);
                    return handleOpenUserDetails();
                  }}
                  style={{
                    backgroundColor: "#0dcaf0",
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {(userInfo?.first_name &&
                    userInfo?.first_name.trim().charAt(0)) || <UserOutlined />}
                </Avatar>
              )
            ) : (
              ""
            )}
          </div>
        </Navbar>
      </header>

      {!["/add-kid", "/notification", "/", "/language", "/login"].includes(
        pathname
      ) && (
          <Sidebar
            {...{
              addClassFlag: isOpen,
              t,
              toggle,
            }}
          />
        )}
      {![
        "/add-kid",
        "/notification",
        "/",
        "/language",
        "/user-list",
        "/diamond",
        "/logout",
        "/login",
      ].includes(pathname) && <SidebarMenu {...{ toggle }} />}
    </>
  );
}

import React from "react";
import { TYPE_OF_ACTION } from "../../../description/constant";
import notifications from "../../Containers/User/Notification";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";
import { checkRestricted } from "../../../shared/CommanFunction";

export default function Notification() {
  const { t } = useTranslation();
  const {
    myRef,
    nextCall,
    currentDoc,
    parentSettingInfo,
    multipleRef,
    handleRequest,
    button,
    notification,
    isPremium,
    loginStatus,
  } = notifications();
  return (
    <div className="sidebar-right-side">
      {checkRestricted(isPremium, loginStatus) ? (
        <div className="row mb-3">{t("notAllowed_i18n")}</div>
      ) : (
        <div className="row justify-content-center">
          <div
            // style={{ display: "flex", flexDirection: "column-reverse" }}
            // onWheel={onWheel}
            className="notification-list"
            // ref={myRef}
            // id="scrollableDiv"
            // style={{
            //   overflow: "auto",
            //   display: "flex",
            // }}
            // onScroll={(e) => console.log(`e`, e)}
          >
            <div
              className="notification-list"
              ref={myRef}
              id="scrollableDiv"
              style={{
                height: window.innerHeight - 100,
                overflow: "auto",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              {notification?.notification?.length ? (
                <InfiniteScroll
                  // dataLength={notification?.notification.length} //This is important field to render the next data
                  // next={nextCall}
                  // onScroll={(e) => console.log(`e`, e)}
                  // hasMore={true}
                  // // hasMore={
                  // //   parentSettingInfo?.notificationsId.length >= currentDoc + 1
                  // // }
                  // loader={<h4>Loading...</h4>}
                  style={
                    {
                      // display: "flex",
                      // height:"100%",
                      // flexDirection: "column-reverse"
                    }
                  }
                  // inverse={true}
                  // endMessage={
                  //   <p style={{ textAlign: "center" }}>
                  //     <b>Yay! You have seen it all</b>
                  //   </p>
                  // }
                  // scrollableTarget="scrollableDiv"

                  dataLength={notification?.notification?.length}
                  next={nextCall}
                  // style={{ display: "flex" }}
                  //To put endMessage and loader to the top.
                  inverse={true}
                  hasMore={
                    parentSettingInfo?.notificationsId?.length >= currentDoc + 1
                  }
                  scrollableTarget="scrollableDiv"
                >
                  {/* <ul> */}
                  {notification?.notification ? (
                    notification?.notification?.map(
                      (
                        {
                          notificationType,
                          value,
                          message,
                          status,
                          request,
                          notificationId,
                          createdAt,
                        },
                        index
                      ) => {
                        return (
                          <div
                            key={index}
                            // style={{
                            //   position: "relative",
                            //   display: "flex",
                            //   flexDirection: "column",
                            //   borderBottom: "1px solid #222222",
                            // }}
                          >
                            {index === notification.unReadMessageIndex && (
                              <li className="unread-notifications" id="unread">
                                <span>{t("unreadNotification_i18n")}</span>
                              </li>
                            )}
                            <li
                            // ref={
                            //   index === notification?.scrollInto ? myRef : null
                            // }
                            >
                              {/* <div ref={addToRefs(index)}> */}
                              <div ref={multipleRef}>
                                <p className="message">{message}</p>
                                {[
                                  TYPE_OF_ACTION.KID_REQUEST,
                                  TYPE_OF_ACTION.PARENT_ADD_KID,
                                ].includes(notificationType) && (
                                  <div className="d-flex justify-content-end mb-md-2 mb-3">
                                    {button.map((el, i) => {
                                      if (status && !status.includes(el))
                                        return (
                                          <React.Fragment
                                            key={i}
                                          ></React.Fragment>
                                        );
                                      else
                                        return (
                                          <input
                                            key={i}
                                            type="button"
                                            className={`${
                                              el === "accept"
                                                ? "btn btn-primary ms-2"
                                                : "btn btn-light"
                                            } ${status && "disabled-cursor"}`}
                                            value={
                                              el === "accept"
                                                ? t("acceptButton_i18n")
                                                : t("denyButton_i18n")
                                            }
                                            disabled={status}
                                            onClick={() =>
                                              handleRequest(
                                                el === "accept"
                                                  ? notificationType ===
                                                    TYPE_OF_ACTION.KID_REQUEST
                                                    ? TYPE_OF_ACTION.PARENT_APPROVED
                                                    : TYPE_OF_ACTION.KID_APPROVED
                                                  : notificationType ===
                                                    TYPE_OF_ACTION.KID_REQUEST
                                                  ? TYPE_OF_ACTION.PARENT_DENIED
                                                  : TYPE_OF_ACTION.KID_DENIED,
                                                value,
                                                notificationId,
                                                request,
                                                el
                                              )
                                            }
                                          />
                                        );
                                    })}
                                  </div>
                                )}
                                {new Date(
                                  createdAt?.seconds * 1000
                                ).toLocaleString()}
                              </div>
                            </li>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <li>{t("none_i18n")}</li>
                  )}
                  {/* </ul> */}
                </InfiniteScroll>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

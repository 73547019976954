import React, { useEffect, useState } from "react";
import ReactFlagsSelect from "react-flags-select";
import { useTranslation } from "react-i18next";
import { LS_VARIABLE } from "../../../../description/constant";
import { getLanguageCountry } from "../../../../shared/CommanFunction";

const LanguageSwitcher = () => {
  const [selected, setSelected] = useState("");

  const { i18n } = useTranslation();

  useEffect(() => {
    document.body.setAttribute(
      "dir",
      ["en", "es", "hi", "ru", "pt", "uk"].includes(i18n.language || "en")
        ? "ltr"
        : "rtl"
    );
    document.body.setAttribute(
      "class",
      ["en", "es", "hi", "ru", "pt", "uk"].includes(i18n.language || "en") ? "dir-ltr" : "dir-rtl"
    );
  }, [selected, i18n.language]);

  const changeLang = (code) => {
    setSelected(code);
    switch (code) {
      case "US":
        i18n.changeLanguage("en");
        break;
      case "IL":
        i18n.changeLanguage("he");
        break;
      case "ES":
        i18n.changeLanguage("es");
        break;
      case "RU":
        i18n.changeLanguage("ru");
        break;
      case "IN":
        i18n.changeLanguage("hi");
        break;
      case "PT":
        i18n.changeLanguage("pt");
        break;
      case "UA":
        i18n.changeLanguage("uk");
        break;
      case "IR":
        i18n.changeLanguage("fa");
        break;
      case "AE":
        i18n.changeLanguage("ar");
        break;
      default:
        i18n.changeLanguage("en");
    }
  };

  return (
    <div className="language-switcher">
      <ReactFlagsSelect
        countries={["US", "IL", "ES", "RU", "PT", "IN", "UA", "AE", "IR"]}
        customLabels={{
          US: "Eng",
          ES: "Esp",
          IL: "He",
          IN: "Hi",
          RU: "Rus",
          PT: "Pt",
          UA: "UA",
          AE: "Ae",
          IR: "Ir",
        }}
        selectedSize={24}
        optionsSize={18}
        showSelectedLabel={false}
        showOptionLabel={false}
        placeholder="Select Language"
        selected={
          selected ||
          getLanguageCountry(
            localStorage.getItem(LS_VARIABLE.I18_NEXT_LANGUAGE)
          ) ||
          "US"
        }
        onSelect={(code) => changeLang(code)}
        className="language-list"
      />
    </div>
  );
};

export default LanguageSwitcher;

import React from "react";
import { Button } from "reactstrap";
import { Spin, Alert, Modal } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { TYPE_OF_STATUS, USER_TYPE } from "../../../description/constant";
import nightModeHours from "./../../Containers/User/NightModeHours";
import {
  checkRestricted,
  handleDeniedActionReasons,
} from "../../../shared/CommanFunction";
import { useTranslation } from "react-i18next";

export default function NightModeHours() {
  const { t } = useTranslation();
  const {
    selectedCategory,
    openAlert,
    deniedNightHourSlot,
    handleCloseAlert,
    scheduleLists,
    removeNightTimeList,
    allDaySelected,
    addNightTimeList,
    removeNightTimeHours,
    addNightTimeHours,
    saveButtonFlag,
    loader,
    handleSaveNightHours,
    isModalVisible,
    handleManageModal,
    isPremium,
    loginStatus,
  } = nightModeHours();

  return (
    <div className="sidebar-right-side side-bar-menu">
      {selectedCategory === USER_TYPE.CHILD_IN_PARENT ||
        checkRestricted(isPremium, loginStatus) ? (
        <div className="row mb-3">{t("notAllowed_i18n")}</div>
      ) : (
        <div className="row night-hour-form justify-content-center">
          <div className="col-12">
            <div className="night-hour-block">
              <div className="row">
                {openAlert && (
                  <div className="mb-3">
                    <Alert
                      message={
                        deniedNightHourSlot?.status === TYPE_OF_STATUS.REQUESTED
                          ? "Sent"
                          : "Fail"
                      }
                      description={`${deniedNightHourSlot && deniedNightHourSlot?.reason
                        ? handleDeniedActionReasons(
                          deniedNightHourSlot?.reason,
                          t
                        )
                        : deniedNightHourSlot?.status ===
                          TYPE_OF_STATUS.REQUESTED
                          ? t("requestSentToParent_i18n")
                          : t("somethingWentWrong_i18n")
                        }.`}
                      type={
                        deniedNightHourSlot?.status === TYPE_OF_STATUS.REQUESTED
                          ? "info"
                          : "error"
                      }
                      closable
                      onClose={handleCloseAlert}
                    />
                  </div>
                )}
                {scheduleLists.map((data, index) => {
                  return (
                    <div className={`night-hours-box mb-3`} key={index}>
                      <div
                        className={`night-hours ${data?.className
                          } cursor-pointer ${data.className && data.default
                            ? `${removeNightTimeList &&
                              removeNightTimeList?.length &&
                              removeNightTimeList.includes(data.value)
                              ? "deselect"
                              : ""
                            } ${removeNightTimeList.includes(allDaySelected) &&
                            "deselect"
                            }`
                            : `${addNightTimeList &&
                              addNightTimeList.length &&
                              addNightTimeList.includes(data.value)
                              ? "selected-box"
                              : ""
                            }`
                          }`}
                        key={index + 4}
                        onClick={() =>
                          data.className && data.default
                            ? removeNightTimeHours(data, index)
                            : addNightTimeHours(data, index)
                        }
                      >
                        {data?.label}
                      </div>
                    </div>
                  );
                })}
                <div className="text-center">
                  <Button
                    disabled={saveButtonFlag || loader}
                    title="Please select any start and end time of night mode"
                    className={`mt-3 px-4 py-1 ${saveButtonFlag ? "disabled-cursor" : "cursor-pointer"
                      }`}
                    color="primary"
                    onClick={handleSaveNightHours}
                  >
                    {t("save_i18n")}
                    {loader && <Spin indicator={<LoadingOutlined />}></Spin>}
                  </Button>
                </div>
                {isModalVisible && (
                  <Modal
                    centered
                    title={t("modalTitleNightHour_i18n")}
                    className="modal-dialog"
                    visible={isModalVisible}
                    onOk={handleManageModal}
                    onCancel={handleManageModal}
                    footer={[
                      <Button
                        key="back"
                        className="primary"
                        onClick={handleManageModal}
                      >
                        {t("ok_i18n")}
                      </Button>,
                      <Button
                        key="submit"
                        className="normal"
                        onClick={handleManageModal}
                      >
                        {t("cancel_i18n")}
                      </Button>,
                    ]}
                  >
                    <p>{t("nightAlertModal1stLine_i18n")}</p>
                    <p>{t("nightAlertModal2stLine_i18n")}</p>
                    <p>{t("nightAlertModal3rdLine_i18n")}</p>
                    <p>{t("nightAlertModal4thLine_i18n")}</p>
                    <p>{t("nightAlertModal5thLine_i18n")}</p>
                    <p>{t("nightAlertModal6thLine_i18n")}</p>
                  </Modal>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from "react";
import loginButton from "../../../Containers/User/LoginButton";
import TelegramLoginButton from "react-telegram-login";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import { handleSetSeen } from "../../../../shared/CommanFunction";
import { useRouter } from "../../../../hooks/use-router";
import { ReactComponent as LoginPageLogo } from "../../../../assets/images/login-page-logo.svg";

export default function LoginPage() {
  const { t } = useTranslation();
  const { pathname } = useRouter();
  const {
    loginStatus,
    handleLogout,
    userInfo,
    handleOpenUserDetails,
    notifications,
  } = loginButton();
  return (
    <div className="login-wrap">
      <LoginPageLogo />
      <h4>Teleteens Control Panel</h4>
      <h3>Manage your settings,your self-lock <br/> and your kids. </h3>
      <h3>With the Telegram bot of Teleteens </h3>
      <Button  href="https://t.me/teleteensBot" target="_blank" color="primary" className="ms-2 py-3 px-5 me-2">
         Teleteens Bot
      </Button >
      {/* className="py-3 link-preview-btn" */}
      {/* <h3>To continue, Login with Telegram:</h3>
      {!loginStatus ? (
        <TelegramLoginButton
          dataAuthUrl="https://teleteen.herokuapp.com/auth/user"
          // dataAuthUrl="https://teleteens-redirect-development.herokuapp.com/auth/user"
          // dataAuthUrl="http://192.168.0.94:4000/auth/user"
          // botName="demoteleteens_bot"
          botName="TeleteensBot"
        />
      ) : (
        <Button className="ms-2 py-1 me-2" color="primary" onClick={handleLogout}>
          {t("logout_i18n")}
        </Button>
      )}
      {loginStatus ? (
        userInfo && userInfo?.photo_url && userInfo?.photo_url ? (
          <Avatar
            onClick={() => {
              pathname === "/notification" && handleSetSeen(userInfo?.id, notifications);
              return handleOpenUserDetails();
            }}
            src={userInfo?.photo_url}
            style={{
              backgroundColor: "#0dcaf0",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {" "}
          </Avatar>
        ) : (
          <Avatar
            onClick={() => {
              pathname === "/notification" && handleSetSeen(userInfo?.id, notifications);
              return handleOpenUserDetails();
            }}
            style={{
              backgroundColor: "#0dcaf0",
              verticalAlign: "middle",
            }}
            size="large"
          >
            {(userInfo?.first_name && userInfo?.first_name.trim().charAt(0)) || <UserOutlined />}
          </Avatar>
        )
      ) : (
        ""
      )} */}
    </div>
  );
}

import React from "react";
import { HIDE_SHOW_FLAG_NAME, USER_TYPE } from "../../../description/constant";
import userThumbnail from "../../../assets/images/userThumbnail.png";
import lobby from "../../Containers/User/Lobby";
import { Alert } from "antd";
import { ReactComponent as PlusIcon } from "../../../assets/images/plus-icon.svg";
import { ReactComponent as EyeIcon } from "../../../assets/images/eye.svg";
import { ReactComponent as ClosedEyeIcon } from "../../../assets/images/eye-closed.svg";

export default function Lobby() {
  const {
    kidRemovedMessage,
    homePage,
    selectedTab,
    handleCloseAlert,
    userInfo,
    handleSelectedTab,
    t,
    selectedCategory,
    parentList,
    handleSettingToggle,
    childrenList,
    push,
    currentPackage,
    onEyeClick,
    hideFlags,
  } = lobby();

  return (
    <div>
      <div className="sidebar-right-side">
        <div className="tab-content" id="pills-tabContent">
          {kidRemovedMessage && (
            <Alert
              message={kidRemovedMessage}
              type="success"
              closable
              showIcon
              onClose={handleCloseAlert}
            />
          )}
          <div
            className={`tab-pane ${homePage.includes(selectedTab) && "active"}`}
            id="home-lists"
            role="tabpanel"
            aria-labelledby="home-lists-tab"
          >
            <div className="heading">
              <h5>{t("myAccount_i18n")} </h5>
            </div>
            <div className="list-box border-bottom mb-3 pb-3">
              <div
                className="mb-3 cursor-pointer"
                onClick={() => handleSelectedTab("My Self", "parent")}
              >
                <div className="home-detail boldName">
                  <div className="chat-user-info align-items-center">
                    <div className="user-img">
                      {userInfo && userInfo?.photo_url ? (
                        <img
                          src={userInfo?.photo_url || userThumbnail}
                          alt="info"
                        />
                      ) : (
                        <img
                          src={userThumbnail}
                          alt="info"
                          className="dummy-img"
                        />
                      )}
                    </div>
                    <div className="account-detail">
                      <h4>
                        {(userInfo?.first_name && userInfo?.first_name) || ""}
                      </h4>
                      <h5>{userInfo?.id || ""}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="heading">
              <h5>
                {t("myParent_i18n")}{" "}
                <span onClick={() => onEyeClick(HIDE_SHOW_FLAG_NAME.PARENT)}>
                  {hideFlags[HIDE_SHOW_FLAG_NAME.PARENT] ? (
                    <EyeIcon />
                  ) : (
                    <ClosedEyeIcon />
                  )}
                </span>
              </h5>
            </div>
            {hideFlags[HIDE_SHOW_FLAG_NAME.PARENT] && (
              <div className="list-box border-bottom mb-3 pb-3">
                {![
                  USER_TYPE.PACKAGE_USER,
                  USER_TYPE.PACKAGE_IN_MODERATOR,
                ].includes(selectedCategory) &&
                parentList &&
                parentList.length ? (
                  parentList.map((child, index) => {
                    return (
                      <div
                        className="mb-3"
                        key={index}
                        onClick={() =>
                          handleSettingToggle(USER_TYPE.CHILD_IN_PARENT, child)
                        }
                      >
                        <div className="home-detail">
                          <div className="chat-user-info align-items-center">
                            <div className="user-img">
                              {child?.userInfo?.photo_url ? (
                                <img
                                  src={
                                    child?.userInfo?.photo_url || userThumbnail
                                  }
                                  alt="info"
                                />
                              ) : (
                                <img
                                  src={userThumbnail}
                                  className="dummy-img"
                                  alt="info"
                                />
                              )}
                            </div>
                            <div className="account-detail">
                              <h4>
                                {child?.firstName
                                  ? `${child?.firstName + child?.lastName}`
                                  : child?.userName || ""}
                              </h4>
                              <h5>{child?.userId || ""}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="mb-4">{t("parentNotAvailableMSG_i18n")}</div>
                )}
              </div>
            )}
            <div className="heading">
              <h5>
                {t("myKids_i18n")}{" "}
                <span onClick={() => onEyeClick(HIDE_SHOW_FLAG_NAME.KID)}>
                  {hideFlags[HIDE_SHOW_FLAG_NAME.KID] ? (
                    <EyeIcon />
                  ) : (
                    <ClosedEyeIcon />
                  )}
                </span>
              </h5>
            </div>
            {hideFlags[HIDE_SHOW_FLAG_NAME.KID] && (
              <div className="list-box">
                {![
                  USER_TYPE.PACKAGE_USER,
                  USER_TYPE.PACKAGE_IN_MODERATOR,
                ].includes(selectedCategory) ? (
                  <>
                    {childrenList.map((child, index) => {
                      return (
                        <div
                          className="mb-3"
                          key={index}
                          onClick={() =>
                            handleSettingToggle(
                              USER_TYPE.PARENT_IN_CHILD,
                              child
                            )
                          }
                        >
                          <div className="home-detail">
                            <div className="chat-user-info align-items-center">
                              <div className="user-img">
                                {child?.userInfo?.photo_url ? (
                                  <img
                                    src={
                                      child?.userInfo?.photo_url ||
                                      userThumbnail
                                    }
                                    alt="info"
                                  />
                                ) : (
                                  <img
                                    src={userThumbnail}
                                    className="dummy-img"
                                    alt="info"
                                  />
                                )}
                              </div>
                              <div className="account-detail">
                                <h4>
                                  {child?.firstName
                                    ? `${child?.firstName + child?.lastName}`
                                    : child?.userName || ""}
                                </h4>
                                <h5>{child?.userId || ""}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div
                      className="mb-3"
                      onClick={() => {
                        push("/add-kid");
                      }}
                    >
                      <div className="add-box">
                        <PlusIcon />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="mb-4">{t("parentNotAvailableMSG_i18n")}</div>
                )}
              </div>
            )}
            {selectedCategory === USER_TYPE.PACKAGE_USER && (
              <>
                <div className="heading">
                  <h5>
                    {t("moderator_i18n")}
                    <span
                      onClick={() => onEyeClick(HIDE_SHOW_FLAG_NAME.MODERATOR)}
                    >
                      {hideFlags[HIDE_SHOW_FLAG_NAME.MODERATOR] ? (
                        <EyeIcon />
                      ) : (
                        <ClosedEyeIcon />
                      )}
                    </span>
                  </h5>
                </div>
                {hideFlags[HIDE_SHOW_FLAG_NAME.MODERATOR] && (
                  <div className="list-box  ">
                    {currentPackage ? (
                      <div
                        className="mb-3"
                        onClick={() =>
                          handleSettingToggle(USER_TYPE.PACKAGE_IN_MODERATOR)
                        }
                      >
                        <div className="home-detail">
                          <div className="chat-user-info align-items-center">
                            <div className="user-img">
                              <img
                                src={userThumbnail}
                                className="dummy-img"
                                alt="info"
                              />
                            </div>
                            <div className="account-detail">
                              <h4>
                                {currentPackage?.name
                                  ? currentPackage?.name
                                  : currentPackage?.moderatorId || ""}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="mb-4">
                        {t("childNotAvailableMSG_i18n")}
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import { Alert } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { USER_TYPE } from "../../../description/constant";
import { checkRestricted } from "../../../shared/CommanFunction";
import PlusButton from "./../../../shared/PlusButton";
import approvalLists from "./../../Containers/User/ApprovalLists";

export default function ApprovalLists() {
  const { t } = useTranslation();
  const {
    selectedCategory,
    openAlert,
    deniedChannelName,
    handleDeniedActionReasons,
    handleCloseAlert,
    channelLists,
    handleShowLoader,
    push,
    loginStatus,
    isPremium,
  } = approvalLists();

  return (
    <>
      <div
        className="tab-pane approval-lists active sidebar-right-side side-bar-menu"
        id="approval-lists"
        role="tabpanel"
        aria-labelledby="approval-lists-tab"
      >
        {selectedCategory === USER_TYPE.CHILD_IN_PARENT ||
        selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR ||
        checkRestricted(isPremium, loginStatus) ? (
          <div className="row mb-3">{t("notAllowed_i18n")}</div>
        ) : (
          <div className="row">
            {openAlert && (
              <div className="mb-3">
                <Alert
                  message={
                    (deniedChannelName && deniedChannelName?.channelName) ||
                    "Fail"
                  }
                  description={`${
                    deniedChannelName &&
                    deniedChannelName?.channelName &&
                    deniedChannelName?.reason
                      ? handleDeniedActionReasons(deniedChannelName?.reason, t)
                      : t("somethingWentWrong_i18n")
                  }.`}
                  type="error"
                  closable
                  showIcon
                  onClose={handleCloseAlert}
                />
              </div>
            )}
            {channelLists && channelLists.length ? (
              channelLists.map((data, index) => {
                return (
                  <div
                    className="chat-detail-wrap col-xl-3 col-lg-3 col-md-6 mb-3"
                    key={index}
                  >
                    <div className="chat-detail">
                      {handleShowLoader(data, index)}
                    </div>
                  </div>
                );
              })
            ) : (
              <div>{t("noChannelAvailableYet_i18n")}</div>
            )}
            <div
              className="add-chat-btn"
              onClick={() => {
                setTimeout(() => {
                  push("/add-chat");
                }, 500);
              }}
            >
              <PlusButton />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

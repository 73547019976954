import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import i18next from "i18next";

const TestAcc = ({ title, list }) => {
  const [isOpen, setOpen] = useState(null);
  const [lists] = useState(list);

  const toggleAccordion = (id) => {
    setOpen(id);
    if (id === isOpen) setOpen(null);
  };

  const renderAccordion = lists.map((item, i) => {
    return (
      <AccordionItem key={item.question}>
        <AccordionHeader className="accordion-question" targetId={String(i)}>
          {i18next.t(item.question)}
        </AccordionHeader>
        <AccordionBody className="accordion-answer" accordionId={String(i)}>
          {i18next.t(item.answer)}
        </AccordionBody>
      </AccordionItem>
    );
  });

  return (
    <div className="faq">
      <h4 className="faq__title">{title}</h4>
      <Accordion className="accordion" open={isOpen} toggle={toggleAccordion}>
        {renderAccordion}
      </Accordion>
    </div>
  );
};

export default TestAcc;

/* eslint-disable react-hooks/rules-of-hooks */
import { useDispatch, useSelector } from "react-redux";
import { LS_VARIABLE } from "../../../description/constant";
import { useRouter } from "../../../hooks/use-router";

export default function loginButton() {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { loginStatus, userInfo, notifications } = useSelector(
    (state) => state.users
  );

  const handleLogout = async () => {
    dispatch({
      type: "PURGE",
    });
    localStorage.removeItem(LS_VARIABLE.LOGGED_USER_INFO);
    localStorage.removeItem(LS_VARIABLE.DEVICE);
    localStorage.removeItem(LS_VARIABLE.IDS);
    dispatch({
      type: "SET_LOGIN_FLAG",
      payload: false,
    });
    dispatch({
      type: "SET_USER_DATA",
      payload: {},
    });
  };

  const handleOpenUserDetails = () => {
    push("/user-list");
  };

  const handleGoToLoginPage = () => {
    push("/login");
  };

  return {
    loginStatus,
    handleLogout,
    userInfo,
    handleOpenUserDetails,
    notifications,
    handleGoToLoginPage,
  };
}

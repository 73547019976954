/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
// User can set simple, frozen and GPS lock in self-lock functionality.
// I get the information of user current status from typeofLock variable, I get the typeofLock from the index page by making conditions on userSettingInfo. If a user is not in any type of lock then the user is able to self-lock, else a lock screen or timers are shown on the screen.

import React, { useEffect, useState } from "react";
import db from "../../../config/firebase";
import dayjs from "dayjs-with-plugins";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Map from "./Map";
// import { handleTimer } from "../../../shared/CommanFunction";
import { useSelector, useDispatch } from "react-redux";
// import { getTimerCount } from "../../../description/userLock";
import waitingIcon from "../../../assets/images/waiting.gif";
import {
  TYPE_OF_STATUS,
  TYPE_OF_ACTION,
  TYPE_OF_LOCK,
  TIMES,
} from "../../../description/constant";
import { useTranslation } from "react-i18next";
import { handleDeniedActionReasons } from "../../../shared/CommanFunction";

export default function selfLock() {
  const { t } = useTranslation();
  const hoursOfOneDay = 24;
  const minutesOfOneHour = 60;
  const secondsOfOneMinute = 60;
  const millisecondsOfOneSecond = 1000;
  const lockTimeLabel = [
    {
      label: "2 mins",
      value: TIMES["2 mins"] * secondsOfOneMinute * millisecondsOfOneSecond,
    },
    {
      label: "5 mins",
      value: TIMES["5 mins"] * secondsOfOneMinute * millisecondsOfOneSecond,
    },
    {
      label: "1 hr",
      value:
        TIMES["1 hr"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      label: "3 hrs",
      value:
        TIMES["3 hrs"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      label: "6 hrs",
      value:
        TIMES["6 hrs"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      label: "24 hrs",
      value:
        TIMES["24 hrs"] *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      label: "2 Days",
      value:
        TIMES["2 Days"] *
        hoursOfOneDay *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      label: "7 days",
      value:
        TIMES["7 days"] *
        hoursOfOneDay *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    {
      label: "30 days",
      value:
        TIMES["30 days"] *
        hoursOfOneDay *
        minutesOfOneHour *
        secondsOfOneMinute *
        millisecondsOfOneSecond,
    },
    { label: "No freeze", value: "noFreeze" },
  ];
  const [simpleLockTime, setSimpleLockTime] = useState();
  const [meltTime, setMeltTime] = useState();
  const [refreezeTime, setRefreezeTime] = useState();
  const [isSubmit, setIsSubmit] = useState(false);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [location, setLocation] = useState();
  const [waiting, setWaiting] = useState(false);
  const [typeOfAlert, setTypeOfAlert] = useState("");

  const dispatch = useDispatch();

  const { selectedId, timers, userInfo, isPremium, loginStatus } = useSelector(
    (state) => state.users
  );
  const userSettingInfo = useSelector(
    (state) => state.users.userList[selectedId]
  );

  useEffect(() => {
    getUpdatedStatus();
  }, [loader]);

  const changeTypeOfLock = (val) =>
    dispatch({
      type: "SET_LOCK_TYPE",
      payload: val,
    });

  useEffect(() => {
    changeTypeOfLock(userSettingInfo?.typeOfLock || "");
    // getTimerCount(userSettingInfo, timers, dispatch);
  }, [userSettingInfo]);

  useEffect(() => {
    waiting && setWaiting(false);
  }, [userSettingInfo?.typeOfLock]);

  // It is function for getting the status of action which is currently executed by user.
  const getUpdatedStatus = () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`;
    db.collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("name", "in", [
        TYPE_OF_ACTION.SIMPLE_LOCK,
        TYPE_OF_ACTION.FROZEN_ICE_LOCK,
        TYPE_OF_ACTION.START_FROZEN_ICE_LOCK,
        TYPE_OF_ACTION.STOP_FROZEN_ICE_LOCK,
        TYPE_OF_ACTION.RELEASES_ICE_TIMER_LOCK,
        TYPE_OF_ACTION.GPS_LOCK,
      ])
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length) {
          setLoader(true);
          getUpdatedStatus();
        } else {
          setLoader(false);
          isSubmit &&
            db
              .collection(
                `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`
              )
              .where("name", "in", [
                TYPE_OF_ACTION.SIMPLE_LOCK,
                TYPE_OF_ACTION.FROZEN_ICE_LOCK,
                TYPE_OF_ACTION.START_FROZEN_ICE_LOCK,
                TYPE_OF_ACTION.STOP_FROZEN_ICE_LOCK,
                TYPE_OF_ACTION.RELEASES_ICE_TIMER_LOCK,
                TYPE_OF_ACTION.GPS_LOCK,
              ])
              .get()
              .then((snapshot) => {
                if (snapshot && snapshot.docs.length) {
                  const { status, reason, name } = snapshot.docs
                    .map((doc) => doc.data())
                    .pop();
                  if (status === TYPE_OF_STATUS.DENIED) {
                    waiting && setWaiting(false);
                    let msg = handleDeniedActionReasons(reason, t);
                    setAlert(msg, "error");
                  } else {
                    if (
                      [
                        TYPE_OF_ACTION.STOP_FROZEN_ICE_LOCK,
                        TYPE_OF_ACTION.RELEASES_ICE_TIMER_LOCK,
                      ].includes(name)
                    ) {
                      timers.map((id) => clearInterval(id));
                      timers.length = 0;
                      dispatch({
                        type: "SET_LOCK_TYPE",
                        payload: "",
                      });
                      dispatch({
                        type: "SET_TIMERS",
                        payload: [],
                      });
                    }
                    setSimpleLockTime();
                    setMeltTime();
                    setRefreezeTime();
                    setLocation();
                    setAlert(t("requestApproved_i18n"), "success");
                  }
                }
              });
          setIsSubmit(false);
        }
      });
  };

  const setAlert = async (message, type) => {
    await setTypeOfAlert(type);
    await setMessage(message);
  };

  //These are the functions for updating the state of lock times or data.
  const handleSimpleLockTime = (value) => {
    if (value === simpleLockTime) {
      setSimpleLockTime();
    } else {
      setSimpleLockTime(value);
    }
  };
  const handleMeltTime = (value) => {
    if (value === meltTime) {
      setMeltTime();
    } else {
      setMeltTime(value);
    }
  };
  const handleRefreezeTime = (value) => {
    if (value === refreezeTime) {
      setRefreezeTime();
    } else {
      setRefreezeTime(value);
    }
  };
  const handleLocation = (loc) => {
    setLocation(loc);
  };

  const date = new Date().getTime();

  //It is function for pass the actions when user set any type of lock.
  const handleSave = (type, value) => {
    setIsSubmit(true);
    setLoader(true);
    const databaseUrl = `${
      process.env.REACT_APP_FIREBASE_COLLECTION
    }/${userInfo?.id.toString()}/actions/${date} - ${type}`;
    const payload = {
      userId: userInfo?.id,
      actionId: `${date}-${type}`,
      actionType:
        type === TYPE_OF_ACTION.GPS_LOCK
          ? `${type}:${userInfo?.id}`
          : `${type}:${value}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      name: `${type}`,
      gpsLocation: { latitude: location?.lat, longitude: location?.lng } || {},
    };
    if (type !== TYPE_OF_ACTION.GPS_LOCK) {
      delete payload.gpsLocation;
    }
    db.doc(databaseUrl).set(payload);
    setWaiting(true);
  };

  // It is function for user's request to stop or release the timer or lock.
  const handleRequest = (type) => {
    setIsSubmit(true);
    setLoader(true);
    const databaseUrl = `${
      process.env.REACT_APP_FIREBASE_COLLECTION
    }/${userInfo?.id.toString()}/actions/${date} - ${type}`;
    db.doc(databaseUrl).set({
      userId: userInfo?.id,
      actionId: `${date}-${type}`,
      actionType: `${type}:${userInfo?.id}`,
      status: TYPE_OF_STATUS.PENDING,
      createdTime: new Date(),
      name: `${type}`,
    });
  };

  //This function is called when user is not in any type of lock and can set the self-lock.
  const showNormal = () => {
    return (
      <div className="self-lock-wrapper">
        <div className="self-lock">
          <h3 className="mb-3">{t("simpleLockHeader_i18n")}</h3>
          <ul>
            {lockTimeLabel
              .filter((el) => el.value !== "noFreeze")
              .map((el, i) => {
                return (
                  <li
                    key={i}
                    className={simpleLockTime === el.value ? "selected" : ""}
                    onClick={() => handleSimpleLockTime(el.value)}
                  >
                    <span>{el.label}</span>
                  </li>
                );
              })}
          </ul>
          <input
            type="button"
            value={t("save_i18n")}
            disabled={simpleLockTime ? false : true}
            onClick={() =>
              handleSave(TYPE_OF_ACTION.SIMPLE_LOCK, simpleLockTime)
            }
            className={`btn btn-primary ${
              !simpleLockTime && "disabled-cursor"
            }`}
          />
        </div>
        <hr />
        <div className="self-lock">
          <h3 className="mb-3">{t("frozenLockHeader_i18n")}</h3>

          <h5>{t("meltTitle_i18n")}</h5>
          <ul>
            {lockTimeLabel
              .filter((el) => el.value !== "noFreeze")
              .map((el, i) => {
                return (
                  <li
                    key={i}
                    className={meltTime === el.label ? "selected" : ""}
                    onClick={() => handleMeltTime(el.label)}
                  >
                    <span>{el.label}</span>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="self-lock">
          <h5>{t("freezeTitle_i18n")}</h5>
          <ul>
            {lockTimeLabel.map((el, i) => {
              return (
                <li
                  key={i}
                  className={refreezeTime === el.label ? "selected" : ""}
                  onClick={() => handleRefreezeTime(el.label)}
                >
                  <span>{el.label}</span>
                </li>
              );
            })}
          </ul>
          <input
            type="button"
            value={t("save_i18n")}
            disabled={meltTime && refreezeTime ? false : true}
            onClick={() =>
              handleSave(
                TYPE_OF_ACTION.FROZEN_ICE_LOCK,
                refreezeTime !== "No freeze"
                  ? `${meltTime}_${refreezeTime}`
                  : meltTime
              )
            }
            className={`btn btn-primary ${
              (!meltTime || !refreezeTime) && "disabled-cursor"
            }`}
          />
        </div>
        <hr />
        <div className="self-lock">
          <h3 className="mb-3">{t("gpsLockHeader_i18n")}</h3>
          <div className="gps-lock mb-4">
            <Map
              defaultLocation={{}}
              handleLocation={handleLocation}
              location={location}
              flag={true}
            />
          </div>
          <input
            type="button"
            value={t("save_i18n")}
            disabled={location ? false : true}
            onClick={() => handleSave(TYPE_OF_ACTION.GPS_LOCK, location)}
            className={`btn btn-primary ${!location && "disabled-cursor"}`}
          />
        </div>
      </div>
    );
  };

  const renderTime = (remainingTime) => {
    const days =
      Math.floor(remainingTime / 86400).toString().length === 1
        ? `0${Math.floor(remainingTime / 86400)}`
        : Math.floor(remainingTime / 86400);
    const hours =
      (Math.floor(remainingTime / 3600) % 24).toString().length === 1
        ? `0${Math.floor(remainingTime / 3600) % 24}`
        : Math.floor(remainingTime / 3600) % 24;
    const minutes =
      Math.floor((remainingTime % 3600) / 60).toString().length === 1
        ? `0${Math.floor((remainingTime % 3600) / 60)}`
        : Math.floor((remainingTime % 3600) / 60);
    const seconds =
      (remainingTime % 60).toString().length === 1
        ? `0${remainingTime % 60}`
        : remainingTime % 60;

    return (
      <div className="time-wrapper">
        <div className="time">
          <div className="time-col">
            {days} <span>{t("day_i18n")}</span>
          </div>
          <div className="time-col">
            {hours}
            <span>{t("hour_i18n")}</span>
          </div>
          <div className="time-col">
            {minutes}
            <span>{t("min_i18n")}</span>
          </div>
          <div className="time-col">
            {seconds}
            <span>{t("sec_i18n")}</span>
          </div>
        </div>
      </div>
    );
  };

  //This is timer function which is used to show timer according to duration passed in arguments. I used the react-countdown-circle-timer package for it.
  //When timer is finished it goes for checking typeofLock and according to change the screen.
  const timerFn = (duration) => {
    const timerProps = {
      isPlaying: true,
      size: 225,
      strokeWidth: 6,
    };
    return (
      <>
        <CountdownCircleTimer
          {...timerProps}
          rotation="counterclockwise"
          onComplete={() => {
            dispatch({
              type: "SET_LOCK_TYPE",
              payload: null,
            });
            setWaiting(true);
          }}
          colors={[["#1640aa"]]}
          duration={duration}
          initialRemainingTime={duration}
          children={({ remainingTime }) => renderTime(remainingTime)}
        ></CountdownCircleTimer>
      </>
    );
  };

  const showSimpleTimer = () => {
    if (userSettingInfo?.lockAccount) {
      const originalDate = new Date(
        userSettingInfo?.lockAccount.seconds * millisecondsOfOneSecond
      );
      const lockAccountMoment = dayjs(originalDate);
      var duration = userSettingInfo?.nightModeTimeZone
        ? lockAccountMoment.diff(dayjs().tz(userSettingInfo?.nightModeTimeZone))
        : lockAccountMoment.diff(dayjs());
      // duration > 0 && handleTimer(true, duration, timers, dispatch);
      return (
        <>
          <div className="self-lock-wrap">
            <h2>{t("simpleLockScreenTitle_i18n")}</h2>
            <div className="timer">
              {duration > 0 && timerFn(duration / millisecondsOfOneSecond)}
            </div>
          </div>
        </>
      );
    }
  };

  //This screen is render when user is in refreezing timer, during the timer period user can unlock by clicking on remove button.
  const showMeltTimer = () => {
    if (userSettingInfo?.refreezeTime) {
      if (dayjs().unix() - userSettingInfo?.refreezeTime?.seconds < 0) {
        var duration =
          (userSettingInfo?.refreezeTime?.seconds - dayjs().unix()) *
          millisecondsOfOneSecond;
      }
      // duration > 0 && changeTypeOfLock("freeze");
      // console.log("duration :>> ", duration);
      // duration > 0 && handleTimer(true, duration, timers, dispatch);
      return (
        <div className="self-lock-wrap">
          <h2>{t("inActiveIceLockScreenTitle_i18n")}</h2>
          <p>{`${
            userSettingInfo?.meltTimeSpan
              ? userSettingInfo.meltTimeSpan
              : "Not added"
          } to melt the lock.`}</p>
          <p>{`${
            userSettingInfo?.freezTimeSpan
              ? userSettingInfo.freezTimeSpan
              : "Not added"
          } to freeze the lock again.`}</p>
          <hr className="mt-4 d-block" />
          <div className="timer mb-3">
            {duration > 0 && timerFn(duration / millisecondsOfOneSecond)}
          </div>
          <input
            type="button"
            value="delete self lock"
            onClick={() =>
              handleRequest(TYPE_OF_ACTION.RELEASES_ICE_TIMER_LOCK)
            }
            className="btn btn-red btn-lg px-5"
          />
        </div>
      );
    }
  };

  //This screen is render when user is in frozen ice timer, user can stop timer and go to startFreeze screen by clicking on stop button.
  const showFreezeTimer = () => {
    if (userSettingInfo?.meltLockTime) {
      if (dayjs().unix() - userSettingInfo?.meltLockTime?.seconds < 0) {
        var duration =
          (userSettingInfo?.meltLockTime?.seconds - dayjs().unix()) *
          millisecondsOfOneSecond;
      }
      // duration > 0 && handleTimer(true, duration, timers, dispatch);
      return (
        <div className="self-lock-wrap">
          <h2>{t("frozenInIceLockScreenTitle_i18n")}</h2>
          <p>{`${
            userSettingInfo?.meltTimeSpan
              ? userSettingInfo.meltTimeSpan
              : "Not added"
          } to melt the lock.`}</p>
          <p>{`${
            userSettingInfo?.freezTimeSpan
              ? userSettingInfo.freezTimeSpan
              : "Not added"
          } to freeze the lock again.`}</p>
          <hr className="mt-4 d-block" />
          <div className="timer mb-3">
            {timerFn(duration / millisecondsOfOneSecond)}
          </div>
          <input
            type="button"
            value="stop"
            onClick={() => handleRequest(TYPE_OF_ACTION.STOP_FROZEN_ICE_LOCK)}
            className="btn btn-red btn-lg px-5"
          />
        </div>
      );
    }
  };

  //It shows user is in gps lock and unlock location on map.
  const showGpsLockScreen = () => {
    return (
      <>
        <div>{t("gpsLockScreenTitle_i18n")}</div>
        <div>{t("gpsUnlockInstruction_i18n")}</div>
        <div>
          <Map
            defaultLocation={{
              lat: userSettingInfo?.gpsLocation?.latitude,
              lng: userSettingInfo?.gpsLocation?.longitude,
            }}
            handleLocation={handleLocation}
            location={location}
            flag={false}
          />
        </div>
      </>
    );
  };

  //It shows the start button for start the frozen ice timer and melt timer.
  const showStartFreezeScreen = () => {
    return (
      <div className="self-lock-wrap">
        <h2>{t("frozenInIceLockScreenTitle_i18n")}</h2>
        <p>{`${
          userSettingInfo?.meltTimeSpan
            ? userSettingInfo.meltTimeSpan
            : "Not added"
        } to melt the lock.`}</p>
        <p>{`${
          userSettingInfo?.freezTimeSpan
            ? userSettingInfo.freezTimeSpan
            : "Not added"
        } to freeze the lock again.`}</p>
        <hr className="mt-4 d-block" />
        <input
          type="button"
          value="Melt ice"
          onClick={() => handleRequest(TYPE_OF_ACTION.START_FROZEN_ICE_LOCK)}
          className="btn btn-primary btn-lg px-5"
        />
      </div>
    );
  };

  //Shows the screen according to the typeofLock.
  const handleShowDetails = () => {
    switch (userSettingInfo?.typeOfLock) {
      case TYPE_OF_LOCK.SIMPLE_LOCK:
        return showSimpleTimer();
      case TYPE_OF_LOCK.FREEZE:
        return showFreezeTimer();
      case TYPE_OF_LOCK.MELT:
        return showMeltTimer();
      case TYPE_OF_LOCK.GPS:
        return showGpsLockScreen();
      case TYPE_OF_LOCK.START_FREEZE:
        return showStartFreezeScreen();
      default:
        return showNormal();
    }
  };

  //It is for automatically remove alert after 5 seconds.
  message &&
    setTimeout(() => {
      setMessage("");
      setTypeOfAlert("");
    }, 3000);

  //It is function for the close the alert notification.
  const handleCloseAlert = () => {
    setMessage("");
    setTypeOfAlert("");
  };

  const showLoader = () => {
    return (
      <div className="waiting-wrap">
        <img src={waitingIcon} alt="wait" />
        <span>{t("loading_i18n")}</span>
      </div>
    );
  };

  //It shows the alert notification and screen according to handleShowDetails function.
  return {
    message,
    typeOfAlert,
    handleCloseAlert,
    waiting,
    showLoader,
    handleShowDetails,
    isPremium,
    loginStatus,
  };
}

import React from "react";
import CustomInputField from "./customInputField";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function CustomForm({ onSubmit, labelName, loader, fields }) {
  return (
    <form onSubmit={onSubmit}>
      <div className="form-group">
        {labelName && <label>{labelName}</label>}
        <div className="kids-input align-items-center position-relative">
          {fields?.map((field, i) => <CustomInputField key={i} {...{ ...field }} />)}
          {loader && (
            <Spin
              indicator={<LoadingOutlined />}
              className="input-loader"
            ></Spin>
          )}
        </div>
      </div>
    </form>
  );
}

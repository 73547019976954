import React from "react";
import { useTranslation } from "react-i18next";
import LoginBtn from "./ui/LoginBtn.jsx";

const PremiumPricing = ({ title }) => {
  const { t } = useTranslation();
  return (
    <div className="pricing">
      <div className="container">
        <h3 className="pricing-title">{title}</h3>
        <div className="pricing__cards">
          <div className="pricing__cards-item">
            <div className="pricing__cards-item__inner">
              <span className="pricing-price">
                {t("PremiumPricingPrice_i18n")}
              </span>
              <p className="pricing-text">{t("PremiumPricingText_i18n")}</p>
              <LoginBtn />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PremiumPricing;

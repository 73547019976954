import React, { useEffect } from "react";
import { ReactComponent as Plus } from "./../assets/images/plus.svg";
import { gsap } from "gsap";

const PlusButton = () => {
  useEffect(() => {
    document.querySelector("#animation_wrapper svg").style.width = "100%";
    document.querySelector("#animation_wrapper svg").style.height = "100%";

    let animId;
    animId = document.querySelector("#animation_wrapper svg").getAttribute("id");
    animId = "#" + animId + " ";
    gsap.set("#animation_wrapper", { opacity: 1 });

    const tl = gsap
      .timeline({
        paused: true,
        repeatRefresh: true,
        repeat: 0,
        defaults: { duration: 0.1 },
      })
      .to(
        animId + "#el",
        {
          scaleY: 0,
          rotation: "random([150, 50, 110, 30, 90])",
          duration: 0.1,
          transformOrigin: "center",
          ease: "elastic.out(1, 0.2)",
        },
        "qq"
      )
      .to(
        animId + "#el2",
        {
          scaleY: "random([1.3, 1.2, 1.1, 1.4])",
          rotation: "random([150, 50, 110, 30, 90])",
          transformOrigin: "center",
          ease: "elastic.out(1, 0.2)",
        },
        "qq"
      )
      .to(
        animId + "#ic",
        {
          scale: 1,
          transformOrigin: "center",
          ease: "elastic.out(1, 0.2)",
          duration: 0.1,
        },
        "qq"
      )
      .to(animId + "#el", {
        scaleY: 1,
        rotation: "random([-150, -50, -110, -30, -90])",
        duration: 1,
        transformOrigin: "center",
        ease: "elastic.out(1, 0.2)",
      })
      .to(
        animId + "#el2",
        {
          scaleY: 0,
          rotation: 0,
          duration: 0.25,
          transformOrigin: "center",
          ease: "elastic.out(1, 0.2)",
        },
        "<"
      )
      .to(
        animId + "#ic",
        {
          scale: 1,
          transformOrigin: "center",
          ease: "elastic.out(1, 0.2)",
          duration: 0.1,
        },
        "<"
      );

    const btnsc = document.querySelector("#btnsc");

    btnsc.addEventListener("mouseenter", function () {
      btnsc.style.cursor = "pointer";
    });

    btnsc.addEventListener("click", function () {
      tl.invalidate();
      tl.restart();
    });
  }, []);
  return (
    <button className="plus-btn" id="animation_wrapper">
      <Plus />
    </button>
  );
};

export default PlusButton;

let questionsList = [
  {
    title: "Group1_Title_i18n",
    id: "1",
    questions: [
      {
        question: "Question1_Group1_i18n",
        answer: "Answer1_Group1_i18n",
      },
      {
        question: "Question2_Group1_i18n",
        answer: "Answer2_Group1_i18n",
      },
      {
        question: "Question3_Group1_i18n",
        answer: "Answer3_Group1_i18n",
      },
      {
        question: "Question4_Group1_i18n",
        answer: "Answer4_Group1_i18n",
      },
      {
        question: "Question5_Group1_i18n",
        answer: "Answer5_Group1_i18n",
      },
    ],
  },
  {
    title: "Group2_Title_i18n",
    id: "2",
    questions: [
      {
        question: "Question1_Group2_i18n",
        answer: "Answer1_Group2_i18n",
      },
      {
        question: "Question2_Group2_i18n",
        answer: "Answer2_Group2_i18n",
      },
      {
        question: "Question3_Group2_i18n",
        answer: "Answer3_Group2_i18n",
      },
      {
        question: "Question4_Group2_i18n",
        answer: "Answer4_Group2_i18n",
      },
      {
        question: "Question5_Group2_i18n",
        answer: "Answer5_Group2_i18n",
      },
    ],
  },
  {
    title: "Group3_Title_i18n",
    id: "3",
    questions: [
      {
        question: "Question1_Group3_i18n",
        answer: "Answer1_Group3_i18n",
      },
      {
        question: "Question2_Group3_i18n",
        answer: "Answer2_Group3_i18n",
      },
      {
        question: "Question3_Group3_i18n",
        answer: "Answer3_Group3_i18n",
      },
      {
        question: "Question4_Group3_i18n",
        answer: "Answer4_Group3_i18n",
      },
      {
        question: "Question5_Group3_i18n",
        answer: "Answer5_Group3_i18n",
      },
    ],
  },
  {
    title: "Group4_Title_i18n",
    id: "4",
    questions: [
      {
        question: "Question1_Group4_i18n",
        answer: "Answer1_Group4_i18n",
      },
      {
        question: "Question2_Group4_i18n",
        answer: "Answer2_Group4_i18n",
      },
      {
        question: "Question3_Group4_i18n",
        answer: "Answer3_Group4_i18n",
      },
    ],
  },
  {
    title: "Group5_Title_i18n",
    id: "5",
    questions: [
      {
        question: "Question1_Group5_i18n",
        answer: "Answer1_Group5_i18n",
      },
      {
        question: "Question2_Group5_i18n",
        answer: "Answer2_Group5_i18n",
      },
      {
        question: "Question3_Group5_i18n",
        answer: "Answer3_Group5_i18n",
      },
      {
        question: "Question4_Group5_i18n",
        answer: "Answer4_Group5_i18n",
      },
      {
        question: "Question5_Group5_i18n",
        answer: "Answer5_Group5_i18n",
      },
    ],
  },
];

const faq = () => {
  return { questionsList };
};

export default faq;

import React from "react";

const Deceleration = () => {

  return (
    <main>
      <div className="deceleration-main">
        <p>
            הצהרת נגישות של האתר טלטין
        <br/><br/>על פי תיקון לתקנות העוסקות בנגישות האינטרנט אתר זה פטור מחובת נגישות. מחזור הכספים של בעל האתר (אורי פרץ) הוא מתחת ל-100 אלף שקלים בשנה ולכן הוא פטור מנגישות
        <br/><br/>במידה ויש בעיות נגישות, תפנו אליי (לאורי פרץ, הבעלים של האתר). ניתן לפנות אליי באמצעות המייל 
        <br/>heretohelpfast@gmail.com
        </p>
      </div>
    </main>
  );
};

export default Deceleration;

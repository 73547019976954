//In this file two common functions are defined
//handleShowChannelNames is used in "Setting.js" file for showing name of setting in which user change.
//handleDeniedActionReasons is used for displaying reason when action is denied.

import db from "../config/firebase";

export const handleDeniedActionReasons = (value, t) => {
  switch (value) {
    case "childOrPackageUser":
      return t("childOrPackageUser_i18n");
    case "underLock":
      return t("underLock_i18n");
    case "onNightMode":
      return t("onNightMode_i18n");
    case "overlapNightMode":
      return t("overlapNightMode_i18n");
    case "girlModeNotValid":
      return t("girlModeNotValid_i18n");
    case "deviceNotFound":
      return t("deviceNotFound_i18n");
    case "noTimezone":
      return t("noTimezone_i18n");
    case "wrongNightMode":
      return t("wrongNightMode_i18n");
    case "inValidData":
      return t("inValidData_i18n");
    case "wrongFrozenLockDetail":
      return t("wrongFrozenLockDetail_i18n");
    default:
      return value;
  }
};

export const handleShowChannelNames = (value, t) => {
  switch (value) {
    case "allowDTA":
      return t("DTA_i18n");
    case "allowAllPictures":
      return t("profilePicture_i18n");
    case "allowAllChannels":
      return t("allChannel_i18n");
    case "allowAllPublicUsers":
      return t("allPublicUser_i18n");
    case "allowAllPublicGroups":
      return t("allPublicGroups_i18n");
    case "allowBots":
      return t("allBots_i18n");
    case "allowAllComments":
      return t("allComment_i18n");
    case "girlMode":
      return t("girlSettings_i18n");
    case "nightMode":
      return t("nightMode");
    case "allowAllPrivateGroups":
      return t("allHugePrivateGroups");
    case "TEEN":
      return t("teen_i18n");
    case "LITTLE":
      return t("little_i18n");
    case "FREE":
      return t("free_i18n");
    default:
      return "";
  }
};

// export const handleTimer = (flag, duration, timers = [], dispatch) => {
//   // console.log(`timers handle timer --`, timers)
//   if (timers && timers.length) {
//     timers.map((id) => clearInterval(id));
//     timers.length = 0;
//     dispatch({
//       type: "SET_TIMERS",
//       payload: [],
//     });
//   }
//   var durationSeconds = duration;
//   const timer = setInterval(() => {
//     // console.log('durationSeconds :>> ', durationSeconds);
//     durationSeconds = durationSeconds - 1000;
//     if (durationSeconds <= 0) {
//       timers && timers.length && timers.map((id) => clearInterval(id));
//       timers.length = 0;
//       setTypeofLock("");
//     }
//   }, 1000);
//   if (timer) {
//     timers.push(timer);
//   }
// };

export const handleSetSeen = (id, notifications) => {
  const temp = notifications?.map((el) => {
    return { ...el, isSeen: true };
  });
  const result = temp?.reduce((r, e) => {
    var k = e.notificationId.split("_")[0];
    if (r[k] || (r[k] = [])) r[k].push(e);
    return r;
  }, {});
  result &&
    Object.entries(result)?.length &&
    Object.entries(result)?.forEach(([key, val]) => {
      var temp2 = {};
      val?.map((el) => (temp2[el.notificationId] = el));
      console.log(`temp2`, temp2);
      db.doc(
        `${
          process.env.REACT_APP_FIREBASE_COLLECTION
        }/${id}/notification/${key.toString()}`
      ).update({ notification: temp2 });
    });
};

export const handleSidebarTitle = (value, t) => {
  switch (value) {
    case "approval-lists":
      return t("approval-lists_i18n");
    case "global-approval-lists":
      return t("global-approval-lists_i18n");
    case "setting":
      return t("setting_i18n");
    case "night-mode":
      return t("night-mode_i18n");
    case "who-am-i":
      return t("who-am-i_i18n");
    case "add-kid":
      return t("add-kid_i18n");
    case "self-lock":
      return t("self-lock_i18n");
    default:
      return "";
  }
};

export const getLanguageCode = (value) => {
  switch (value) {
    case "US":
      return "en";
    case "IN":
      return "hi";
    case "IL":
      return "he";
    case "PT":
      return "pt";
    case "RU":
      return "ru";
    case "IR":
      return "fa";
    case "ES":
      return "es";
    case "UA":
      return "uk";
    case "AE":
      return "ar";
    default:
      return "";
  }
};

export const getLanguageCountry = (value) => {
  switch (value) {
    case "en":
      return "US";
    case "hi":
      return "IN";
    case "he":
      return "IL";
    case "pt":
      return "PT";
    case "ru":
      return "RU";
    case "fa":
      return "IR";
    case "es":
      return "ES";
    case "uk":
      return "UA";
    case "ar":
      return "AE";
    default:
      return "";
  }
};

export const changeLanguage = (lng, i18next) => {
  document.body.setAttribute(
    "dir",
    ["en", "es", "hi", "ru", "pt", "uk"].includes(lng || "en") ? "ltr" : "rtl"
  );
  document.body.setAttribute(
    "class",
    ["en", "es", "hi", "ru", "pt", "uk"].includes(lng || "en") ? "dir-ltr" : "dir-rtl"
  );
  i18next.changeLanguage(lng || "en");
};

export const getConclusion = (data) => {
  if (
    data?.premiumAndroid?.conclusion ||
    data?.premiumApple?.conclusion ||
    data?.premiumWeb?.conclusion
  ) {
    return true;
  }
  return false;
};

export const checkRestricted = (conclusion, isLogin) => {
  if (!isLogin) {
    return true;
  } else if (!conclusion) {
    return true;
  } else {
    return false;
  }
};

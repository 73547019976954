import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import i18next from "i18next";
import { Fragment } from "react/cjs/react.production.min";

const FAQQuestions = ({ list, filteredQuestions, searchInput }) => {
  const [isOpen, setOpen] = useState(null);
  const [lists] = useState(list);

  const toggleAccordion = (id) => {
    setOpen(id);
    if (id === isOpen) setOpen(null);
  };

  const renderAccordion = lists.map((item, i) => {
    return (
      <div className="faq__view-list " key={item.id}>
        <h3 className="faq__view-list__title">{i18next.t(item.title)}</h3>
        <Accordion className="accordion" open={isOpen} toggle={toggleAccordion}>
          {item.questions.map((list, i) => {
            return (
              <AccordionItem key={list.question}>
                <AccordionHeader
                  className="accordion-question"
                  targetId={String(item.id + i)}
                >
                  {i18next.t(list.question)}
                </AccordionHeader>
                <AccordionBody
                  className="accordion-answer"
                  accordionId={String(item.id + i)}
                >
                  {i18next.t(list.answer)}
                </AccordionBody>
              </AccordionItem>
            );
          })}
        </Accordion>
      </div>
    );
  });

  const renderFilteredAccardion = filteredQuestions.map((item, i) => {
    return (
      <AccordionItem key={i}>
        <AccordionHeader className="accordion-question" targetId={String(i)}>
          {i18next.t(item.question)}
        </AccordionHeader>
        <AccordionBody className="accordion-answer" accordionId={String(i)}>
          {i18next.t(item.answer)}
        </AccordionBody>
      </AccordionItem>
    );
  });

  return (
    <div className="faq__view-questions">
      <div className="faq__view-questions-lists">
        {searchInput !== "" ? (
          <Fragment>
            <span className="search-label">
              {i18next.t("FAQViewSearchResult_i18n")}{" "}
              <span className="search-label_result">{searchInput}</span>
            </span>
            <Accordion
              className="accordion"
              open={isOpen}
              toggle={toggleAccordion}
            >
              {renderFilteredAccardion}
            </Accordion>
          </Fragment>
        ) : (
          renderAccordion
        )}
      </div>
    </div>
  );
};

export default FAQQuestions;

import React from "react";
import { useTranslation } from "react-i18next";
import {
  androidWhite,
  appleWhite,
} from "../../Containers/NewLandingPage/imports";

const Apps = () => {
  const { t } = useTranslation();
  return (
    <div className="apps" id="downloads">
      <div className="container">
        <div className="apps__inner">
          <h4 className="apps__title">
            {t("AppsTitle_i18n")}
            <span className="dark">{t("AppsTitleSpan_i18n")}</span>
          </h4>
          <div className="apps__buttons">
            <a
              href="https://apps.apple.com/mn/app/teleteens/id1516268121"
              className="btn-app"
              target="_blank"
              rel="noreferrer"
            >
              <img className="apps__icon" src={appleWhite} alt="" />
              iOS App
            </a>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/webpage-teleteens.appspot.com/o/Teleteens%20X%208.9.3.8.apk?alt=media&token=1936cc19-c174-409b-8b18-92cb951082a4"
              className="btn-app"
              target="_blank"
              rel="noreferrer"
            >
              <img className="apps__icon" src={androidWhite} alt="" />
              Android App
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Apps;

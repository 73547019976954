import { thumbnail1 } from './imports';

const data = {
  src: 'https://www.youtube.com/embed/bcgBIPoywwU',
  thumbnail: thumbnail1
};
const video = () => {
  return { data };
};

export default video;

/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */

//In this setting functionality, user can manage the settings. there are different setting options like general, special and girl settings.
//If user is kid then his all change setting request is sent to parent, if his parent is in night mode then kid is not able to sent request.
//If parent is in "Kid settings" then he also see the manage kid setting.
//For "Manage kid" setting "ManageKid.js" file will be running.

import { useEffect, useState } from "react";
import db from "../../../config/firebase";
import {
  handleDeniedActionReasons,
  handleShowChannelNames,
} from "../../../shared/CommanFunction";
import { useSelector, useDispatch } from "react-redux";
import {
  LS_VARIABLE,
  TYPE_OF_STATUS,
  USER_TYPE,
} from "../../../description/constant";
import { useRouter } from "../../../hooks/use-router";
import { useTranslation } from "react-i18next";

const status = [TYPE_OF_STATUS.DENIED, TYPE_OF_STATUS.REQUESTED];

export default function settings({ deviceIdInfo }) {
  const { t } = useTranslation();
  const [girlSetting, setGirlSetting] = useState("");
  const [generalSetting, setGeneralSetting] = useState({});
  const [specialSetting, setSpecialSetting] = useState({});
  const [pendingRequestList, setPendingRequestList] = useState([]);
  const [deniedSettingRequest, setDeniedSettingRequest] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [DTAFlag, setDTAFlag] = useState(false);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const { push } = useRouter();
  const userInfo = JSON.parse(
    localStorage.getItem(LS_VARIABLE.LOGGED_USER_INFO)
  );
  // eslint-disable-next-line no-unused-vars
  const [deviceId, setDeviceId] = useState("");
  let actionsChanges = db.collection(
    `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`
  );
  let getDTASnapShot = db.collection(`devices/`);
  const {
    selectedId,
    selectedCategory,
    packageDetail,
    loginStatus,
    isPremium,
  } = useSelector((state) => state.users);
  const userSettingInfo = useSelector(
    (state) => state.users.userList[selectedId]
  );
  const parentSettingInfo = useSelector(
    (state) => state.users.userList[userInfo?.id]
  );
  const packageUser = useSelector(
    (state) =>
      state.users.userList[selectedId]?.filteringCompaniesUserSettings &&
      state.users.userList[selectedId]?.filteringCompaniesUserSettings[
        userSettingInfo?.filteringCompanyPackageName
      ]
  );
  // const disabled = selectedCategory === "packageInModerator" ? true : false

  useEffect(() => {
    if (userInfo && Object.keys(userInfo).length) {
      handelGetPendingStatusDataList();
    }
  }, []);

  useEffect(() => {
    handleSetUpdate();
    handelGetPendingStatusDataList();
  }, [userSettingInfo, DTAFlag, parentSettingInfo]);

  useEffect(() => {
    if (loading) {
      setOpenAlert(true);
      handleSetUpdate();
    }
  }, [loading]);

  useEffect(() => {
    handleGetDTAValue();
  }, [userInfo]);

  useEffect(() => {
    getUpdatedStatus();
    getUpdatedDTAStatus();
  }, []);

  useEffect(() => {
    if (deniedSettingRequest) {
      handelGetPendingStatusDataList();
    }
  }, [deniedSettingRequest]);

  //To get DTA value
  const handleGetDTAValue = async () => {
    if (Object.keys(userInfo).length) {
      const snapshotData = await (
        await db.doc(`users-ids/${userInfo?.id}`).get()
      ).data();
      if (snapshotData) {
        setDeviceId(snapshotData?.deviceId || "");
        localStorage.setItem(LS_VARIABLE.DEVICE, snapshotData?.deviceId);
        const getDTAInfo = await (
          await db.doc(`devices/${snapshotData?.deviceId}`).get()
        ).data();
        if (getDTAInfo && Object.keys(getDTAInfo).length) {
          if (getDTAInfo?.defenseSystemActivated !== DTAFlag)
            setDTAFlag(getDTAInfo?.defenseSystemActivated);
        } else {
          DTAFlag && setDTAFlag(false);
        }
      } else {
        DTAFlag && setDTAFlag(false);
      }
    }
  };

  //For adding requests which is currently pending.
  const handleUpdatePendingRequestData = (value) => {
    setPendingRequestList([...pendingRequestList, value]);
  };

  //For updating the value of settings.
  const handleSetUpdate = () => {
    if (Object.keys(userSettingInfo).length) {
      setGirlSetting(
        selectedCategory === USER_TYPE.PACKAGE_USER
          ? packageUser?.girlMode
            ? packageUser?.girlMode
            : packageDetail.defaultUserSettings.girlMode
          : selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR
          ? packageDetail.defaultUserSettings.girlMode
          : userSettingInfo?.girlMode || ""
      );
      const {
        allowAllChannels,
        allowAllComments,
        allowAllPictures,
        allowAllPrivateGroups,
        allowAllPublicGroups,
        allowAllPublicUsers,
        allowBots,
      } =
        selectedCategory === USER_TYPE.PACKAGE_USER
          ? {
              ...packageDetail.defaultUserSettings.limits,
              ...packageUser?.limits,
            }
          : selectedCategory === USER_TYPE.PACKAGE_IN_MODERATOR
          ? packageDetail.defaultUserSettings.limits
          : userSettingInfo.limits;
      setGeneralSetting({
        allowAllChannels,
        allowAllPrivateGroups,
        allowAllPublicGroups,
        allowAllPublicUsers,
        allowBots,
      });
      setSpecialSetting({
        allowDTA: DTAFlag,
        allowAllComments,
        allowAllPictures,
      });
      loading && setLoading(false);
    }
  };

  //To get updated status of action which is pass for changing the settings
  const getUpdatedStatus = () => {
    actionsChanges.onSnapshot((snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "modified") {
          if (
            change.doc.data()?.type &&
            change.doc.data()?.type.includes("Settings") &&
            status.includes(change.doc.data()?.status)
          ) {
            if (change.doc.data()?.name === "removeKid") {
              setLoader(false);
            }
            !loading && setLoading(true);
            JSON.stringify(deniedSettingRequest) !==
              JSON.stringify(change.doc.data()) &&
              setDeniedSettingRequest(change.doc.data());
          } else {
            if (change.doc.data()?.name === "removeKid") {
              setLoader(false);
              dispatch({
                type: "SET_KID_REMOVED_MESSAGE",
                payload: t("kidRemoved_i18n"),
              });
              push("/user-list");
            }
          }
        }
      });
    });
  };

  //To get updated DTA status
  const getUpdatedDTAStatus = () => {
    if (deviceIdInfo) {
      getDTASnapShot.onSnapshot(deviceIdInfo, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (
            change.type === "modified" &&
            change.doc.data().account?.toString() ===
              userSettingInfo?.userId?.toString()
          ) {
            if (change.doc.data()?.defenseSystemActivated !== DTAFlag)
              setDTAFlag(change.doc.data()?.defenseSystemActivated || false);
          }
        });
      });
    }
  };

  //Foe change event in girl settings.
  const handleUpdateGirlSetting = (value) => {
    if (value !== girlSetting) {
      setGirlSetting(value || "");
      handleCallActions(value, "girlMode");
    }
  };

  //Foe change event in general settings.
  const handleUpdateGeneralSetting = (name) => {
    handleCallActions(!generalSetting[name], name);
    setGeneralSetting({
      ...generalSetting,
      [name]: !generalSetting[name],
    });
  };

  //Foe change event in special settings.
  const handleUpdateSpecialSetting = (name) => {
    handleCallActions(!specialSetting[name], name);
    setSpecialSetting({
      ...specialSetting,
      [name]: !specialSetting[name],
    });
  };

  //For passing action of changing settings
  const handleCallActions = async (value, type) => {
    type === "removeKid" && !loader && setLoader(true);
    openAlert && setOpenAlert(false);
    const date = new Date().getTime();
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions/${date}-${type}`;
    const payload = {
      userId:
        type === "manageKidAccess" || type === "removeKid"
          ? userInfo?.id
          : parseInt(userSettingInfo?.userId),
      actionId: date + "-" + type,
      actionType: `${type}:${value}`,
      status: TYPE_OF_STATUS.PENDING,
      type: "Settings",
      name: type === "girlMode" ? value : type,
      createdTime: new Date(),
    };
    handleUpdatePendingRequestData(payload);
    db.doc(databaseUrl).set(payload);
  };

  //To get actions which is in PENDING status
  const handelGetPendingStatusDataList = async () => {
    const databaseUrl = `${process.env.REACT_APP_FIREBASE_COLLECTION}/${userInfo?.id}/actions`;
    let arrayInfo = [];
    await db
      .collection(databaseUrl)
      .where("status", "==", TYPE_OF_STATUS.PENDING)
      .where("type", "==", "Settings")
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length) {
          snapshot.docs.forEach((d, i) => {
            arrayInfo.push(snapshot.docs[i].data());
            arrayInfo[i].id = snapshot.docs[i].id;
          });
        }
      });
    setPendingRequestList(arrayInfo);
  };

  //For displaying the checkboxes when request is pending and loader is running.
  const handleDisabledCheckBox = (value) => {
    let flagValue = false;
    if (value && pendingRequestList && pendingRequestList.length) {
      pendingRequestList.forEach((data) => {
        if (!flagValue) if (data?.actionType.includes(value)) flagValue = true;
      });
    }
    return flagValue;
  };

  //For disabling checkboxes.
  const handleGirlModesDisabledCheckBox = (value) => {
    let flagValue = false;
    if (value && pendingRequestList && pendingRequestList.length) {
      flagValue = pendingRequestList.some(({ name }) => name === value);
    }
    return flagValue;
  };

  //For closing the alert notification.
  const handleCloseAlert = () => {
    setOpenAlert(false);
    setDeniedSettingRequest("");
  };

  return {
    selectedCategory,
    openAlert,
    userInfo,
    pendingRequestList,
    girlSetting,
    handleGirlModesDisabledCheckBox,
    handleUpdateSpecialSetting,
    handleUpdateGirlSetting,
    deniedSettingRequest,
    generalSetting,
    specialSetting,
    handleUpdateGeneralSetting,
    handleShowChannelNames,
    handleDeniedActionReasons,
    loader,
    handleCloseAlert,
    handleCallActions,
    userSettingInfo,
    handleDisabledCheckBox,
    selectedId,
    isPremium,
    loginStatus,
  };
}
